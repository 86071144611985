

.promo {
  position: relative;
    &__text {
        cursor: pointer;
        color: $color-a;
        box-shadow: 0 1px 0 $color-a;
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        margin-top: 0;
        &:hover {
          box-shadow: none;
        }
    }
    &__field {
        display: none;
        background-color: transparent;
        transition: 0.3s ease-out;
        font-size: 18px;
        line-height: normal;
        height: 45px;
        color: $color-bg;
        border: none;
        border-bottom: 1px solid rgba(170,189,198,0.3);
        border-radius: 0;
        outline: none;
        box-shadow: none;
    }
    &--active {
        cursor: default;
        color: rgba(137,153,169,0.5);
        font-size: 12px;
        box-shadow: none;
    }
}

.p-error {
  margin-top: 0;
  position: absolute;
  bottom: -15px;
  left: 0;
  color: $color-red-medium;
  font-size: 12px;
  margin-bottom: 0;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.payment-wrapper-form {
  background-color: rgba(160, 180, 190, .12);
  padding: 30px;
  margin-left: -30px;
  margin-right: -30px;
  @include media-breakpoint-down(xs) {
    margin-left: 0;
    margin-right: 0;
  }
  .login__description {
    margin-top: 0;
  }
}

.grey-text {
  color: $color-grey-text !important;
}


.consult--phone {
  margin-right: 20px;
}

.registration-rules-confirm {
  margin-top: 40px;
}