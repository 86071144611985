.share-btn {
  width: 130px;
  padding-left: 40px;
  height: 50px;
  margin-right: 10px;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  @include media-breakpoint-down(xs) {
    margin-bottom: 15px;
  }
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    left: 15px;
    bottom: 0;
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  &:hover {
    opacity: 0.7;
  }
  &--fb {
    background-color: $color--fb;
    &::before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDExIDMwIj48dGl0bGU+aWNvbi1mYi13aGl0ZTwvdGl0bGU+PHBhdGggZD0iTS4wNSwxMC42MUgyLjM5VjguMjlhNi4yNyw2LjI3LDAsMCwxLC43NS0zLjU2QTQuMTMsNC4xMywwLDAsMSw2Ljc2LDNhMTQuNDMsMTQuNDMsMCwwLDEsNC4xOC40M2wtLjU4LDMuNTJhNy43Niw3Ljc2LDAsMCwwLTEuODgtLjI5Yy0uOTEsMC0xLjcyLjMzLTEuNzIsMS4yNnYyLjY5aDMuNzJMMTAuMjIsMTRINi43NlYyNkgyLjM5VjE0SC4wNVoiIHN0eWxlPSJmaWxsOiNmZmYiLz48L3N2Zz4=');
    }
  }
  &--vk {
    background-color: $color--vk;
    &::before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNiIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDI2IDMwIj48dGl0bGU+aWNvbi12a25ldy13aGl0ZTwvdGl0bGU+PHBhdGggZD0iTTEyLjcyLDIyLjQ0aDEuNTVhMS4zLDEuMywwLDAsMCwuNzEtLjMxLDEuMTYsMS4xNiwwLDAsMCwuMjEtLjY5czAtMi4xMS45NC0yLjQyLDIuMTgsMiwzLjQ4LDIuOTRhMi40NCwyLjQ0LDAsMCwwLDEuNzMuNTNsMy40NywwczEuODItLjExLDEtMS41NmExMS42OCwxMS42OCwwLDAsMC0yLjU4LTNjLTIuMTgtMi0xLjg5LTEuNzEuNzQtNS4yNSwxLjYtMi4xNSwyLjI0LTMuNDcsMi00cy0xLjM3LS4zOS0xLjM3LS4zOWwtMy45MSwwYS44OC44OCwwLDAsMC0uNS4wOSwxLjEsMS4xLDAsMCwwLS4zNS40MiwyMy4xLDIzLjEsMCwwLDEtMS40NCwzLjA4Yy0xLjc0LDMtMi40NCwzLjE1LTIuNzIsMy0uNjYtLjQzLS41LTEuNzQtLjUtMi42NiwwLTIuOS40My00LjExLS44NS00LjQyQTYuNjMsNi42MywwLDAsMCwxMi41LDcuNWE4LjM1LDguMzUsMCwwLDAtMy4yNC4zNGMtLjQ0LjIyLS43OS43MS0uNTguNzRhMS43NSwxLjc1LDAsMCwxLDEuMTUuNTlBMy44NSwzLjg1LDAsMCwxLDEwLjIyLDExcy4yMywzLjQxLS41NCwzLjgzYy0uNTMuMjktMS4yNS0uMy0yLjgtM2EyNS4zNywyNS4zNywwLDAsMS0xLjQtMi45MiwxLjE4LDEuMTgsMCwwLDAtLjMyLS40NCwxLjYyLDEuNjIsMCwwLDAtLjYtLjI0bC0zLjcyLDBzLS41NiwwLS43Ni4yNiwwLC42NywwLC42N1MzLDE2LDYuMjcsMTkuNDdhOC44Nyw4Ljg3LDAsMCwwLDYuNDUsM1oiIHN0eWxlPSJmaWxsOiNmZmY7ZmlsbC1ydWxlOmV2ZW5vZGQiLz48L3N2Zz4=');
    }
  }
  &--g {
    background-color: $color--g;
    &::before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDMwIDMwIj48dGl0bGU+aWNvbi1nLXdoaXRlPC90aXRsZT48cGF0aCBkPSJNMCwxNC41MmE5LjQ5LDkuNDksMCwwLDEsOS41LTksOS42Nyw5LjY3LDAsMCwxLDYuMzYsMi4zNCwzMy44NiwzMy44NiwwLDAsMS0yLjQyLDIuNDljLTIuMTctMS40OS01LjI1LTEuOTItNy40MS0uMmE2LjA3LDYuMDcsMCwwLDAtLjI2LDkuNDhDOC43MSwyMi4yNSwxNC4xOCwyMSwxNSwxNi45NGMtMS44MiwwLTMuNjMsMC01LjQ1LS4wNiwwLTEuMDgsMC0yLjE2LDAtMy4yNCwzLDAsNi4wNywwLDkuMTEsMEExMC44NiwxMC44NiwwLDAsMSwxNi45MiwyMWMtMi4zNywzLjMyLTcuMTMsNC4zLTEwLjg0LDIuODdBOS41MSw5LjUxLDAsMCwxLDAsMTQuNTJaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBhdGggZD0iTTI0LjU1LDEwLjkxaDIuNzFjMCwuOSwwLDEuODEsMCwyLjcxbDIuNzIsMHYyLjdsLTIuNzIsMHEwLDEuMzYsMCwyLjcxSDI0LjU1YzAtLjksMC0xLjgxLDAtMi43MWwtMi43Miwwdi0yLjdsMi43MiwwUTI0LjU0LDEyLjI3LDI0LjU1LDEwLjkxWiIgc3R5bGU9ImZpbGw6I2ZmZiIvPjwvc3ZnPg==');
    }
  }
  &--tw {
    background-color: $color--tw;
    margin-right: 0;
    &::before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDIyIDMwIj48dGl0bGU+aWNvbi10dy13aGl0ZTwvdGl0bGU+PHBhdGggZD0iTTE5Ljc1LDEwLjQ4YzAsLjIsMCwuMzksMCwuNTlBMTIuODIsMTIuODIsMCwwLDEsMCwyMiw5LDksMCwwLDAsMS4wOCwyMmE5LDksMCwwLDAsNS42MS0xLjk0LDQuNTIsNC41MiwwLDAsMS00LjIyLTMuMTYsNC41LDQuNSwwLDAsMCwyLS4wOEE0LjU0LDQuNTQsMCwwLDEsLjg4LDEyLjM5czAsMCwwLS4wNmE0LjQ4LDQuNDgsMCwwLDAsMiwuNTcsNC41Nyw0LjU3LDAsMCwxLTEuNC02LjA3LDEyLjc5LDEyLjc5LDAsMCwwLDkuMyw0Ljc1LDQuNjQsNC42NCwwLDAsMS0uMTItMSw0LjUxLDQuNTEsMCwwLDEsNy44MS0zLjExLDksOSwwLDAsMCwyLjg3LTEuMSw0LjU1LDQuNTUsMCwwLDEtMiwyLjUxQTksOSwwLDAsMCwyMiw4LjEzLDkuMjEsOS4yMSwwLDAsMSwxOS43NSwxMC40OFoiIHN0eWxlPSJmaWxsOiNmZmYiLz48L3N2Zz4=');
    }
  }
}

.add-to-cal {
  padding-left: 30px;
  .p--normal {
    margin-top: 15px;
  }
}

.addevent {
  @extend a;
  box-shadow: 0 1px 0 $color-a--hover;
  position: relative;
  &::before {
    content: '';
    width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    top: 5px;
    left: -30px;
  }
  &:hover {
    box-shadow: none;
  }
  &--google::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij48dGl0bGU+aWNvbi1nY2FsPC90aXRsZT48cGF0aCBkPSJNMTIuNzEsMTdIMy41N0EzLjU3LDMuNTcsMCwwLDEsMCwxMy40M1YzLjU3QTMuNTcsMy41NywwLDAsMSwzLjU3LDBoOS44NUEzLjU3LDMuNTcsMCwwLDEsMTcsMy41N3Y5LjE0IiBzdHlsZT0iZmlsbDojM2E4M2YzIi8+PGNpcmNsZSBjeD0iMy45NiIgY3k9IjEuOTQiIHI9IjAuNzUiIHN0eWxlPSJmaWxsOiNmZmYiLz48Y2lyY2xlIGN4PSIxMy4wNCIgY3k9IjEuOTQiIHI9IjAuNzUiIHN0eWxlPSJmaWxsOiNmZmYiLz48cGF0aCBkPSJNNSw4LjFINS45YTEuMTEsMS4xMSwwLDAsMCwuODYtLjNBMS4yLDEuMiwwLDAsMCw3LDdhMS4xLDEuMSwwLDAsMC0uMjgtLjc4QTEuMDYsMS4wNiwwLDAsMCw2LDUuODhhMS4xNCwxLjE0LDAsMCwwLS43Ny4yNy44OC44OCwwLDAsMC0uMzEuN0gzLjN2MEExLjg0LDEuODQsMCwwLDEsNCw1LjI0YTIuOTEsMi45MSwwLDAsMSwxLjg5LS42MiwzLjExLDMuMTEsMCwwLDEsMiwuNiwyLjA3LDIuMDcsMCwwLDEsLjc1LDEuNzEsMS43MiwxLjcyLDAsMCwxLS4zMywxLDIuMjIsMi4yMiwwLDAsMS0uOTIuNzQsMi4wNywyLjA3LDAsMCwxLDEsLjczLDEuOTMsMS45MywwLDAsMSwuMzUsMS4xNUEyLjExLDIuMTEsMCwwLDEsOCwxMi4zMiwzLjI1LDMuMjUsMCwwLDEsNS45MSwxMywzLjE3LDMuMTcsMCwwLDEsNCwxMi4zNmExLjkxLDEuOTEsMCwwLDEtLjc4LTEuNjl2MEg0Ljc3YTEsMSwwLDAsMCwuMzMuNzcsMS4yMSwxLjIxLDAsMCwwLC44NS4zLDEuMjMsMS4yMywwLDAsMCwuODktLjMxLDEuMTEsMS4xMSwwLDAsMCwuMzMtLjg0LDEuMjIsMS4yMiwwLDAsMC0uMzItLjk0LDEuMzQsMS4zNCwwLDAsMC0uOTQtLjNINVoiIHN0eWxlPSJmaWxsOiNmZmYiLz48cGF0aCBkPSJNMTMuNDQsMTIuODVIMTEuODFWNi4xOEgxMC4xNlY1bDMuMjctLjI5WiIgc3R5bGU9ImZpbGw6I2ZmZiIvPjxwb2x5Z29uIHBvaW50cz0iMTIuNzEgMTcgMTcgMTIuNzEgMTIuNzEgMTIuNzEgMTIuNzEgMTciIHN0eWxlPSJmaWxsOiNhMWMzZmYiLz48cG9seWxpbmUgcG9pbnRzPSIxMi43MSAxMi43MSA4LjQzIDE3IDEyLjcxIDE3IiBzdHlsZT0iZmlsbDojMjc1N2ExIi8+PC9zdmc+');
  }
  &--ical::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij48dGl0bGU+aWNvbi1pY2FsPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjE1LjgzIDQuNDEgMC43NCA3LjQyIDAgMy41IDE1LjEgMC41IDE1LjgzIDQuNDEiIHN0eWxlPSJmaWxsOiNjZjU4NTQiLz48cG9seWdvbiBwb2ludHM9IjE1LjgzIDQuNDEgMC43NCA3LjQyIDIuNDUgMTYuNSAxNC43MyAxNC4wNiAxNyAxMC42IDE1LjgzIDQuNDEiIHN0eWxlPSJmaWxsOiNmZmYiLz48cGF0aCBkPSJNNC42NSw5LjkzLDQuNDgsOUE1LjUyLDUuNTIsMCwwLDAsNS4zLDguOGExLjIsMS4yLDAsMCwwLC41NS0uNDdBMS4xOCwxLjE4LDAsMCwwLDYsNy44MmExLjEzLDEuMTMsMCwwLDAsMC0uMjdsMS0uMjEsMS4yNCw2LjU2TDcsMTQuMTUsNi4xNSw5LjYzWiIgc3R5bGU9ImZpbGw6IzM1MzUzNSIvPjxwYXRoIGQ9Ik0xMy42MSw3LjE2QTguMzgsOC4zOCwwLDAsMCwxMyw4LjM0YTguNyw4LjcsMCwwLDAtLjc2LDMuMjcsNi40Nyw2LjQ3LDAsMCwwLC4wNiwxLjQ5bC0xLjMuMjZhOC4yMyw4LjIzLDAsMCwxLC40NC0zLjg5LDguOTMsOC45MywwLDAsMSwuODUtMS44OGwtMy4xOC42My0uMi0xLjE2LDQuNTEtLjlaIiBzdHlsZT0iZmlsbDojMzUzNTM1Ii8+PHBhdGggZD0iTTIuNzMsNS44NGEuODIuODIsMCwwLDEsMCwuMzguNTEuNTEsMCwwLDEtLjQzLjMzLjU1LjU1LDAsMCwxLS4zNywwLC40OS40OSwwLDAsMS0uMjItLjM3bDAtLjEzLjIzLDAsMCwuMTNBLjM0LjM0LDAsMCwwLDIsNi4zYS4yNi4yNiwwLDAsMCwuMjIsMCwuMjYuMjYsMCwwLDAsLjIzLS4xOS45My45MywwLDAsMCwwLS4zM0wyLjI0LDQuNThsLjI0LDBaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBhdGggZD0iTTMuMTIsNC40MWwuMjIsMS4xNWEuNjkuNjksMCwwLDAsLjE0LjMyQS4zOS4zOSwwLDAsMCwzLjg4LDZhLjQ1LjQ1LDAsMCwwLC4zOS0uMy43Mi43MiwwLDAsMCwwLS4zM0w0LjA1LDQuMjJsLjI1LDAsLjIsMWExLjI2LDEuMjYsMCwwLDEsMCwuNTUuNjUuNjUsMCwwLDEtLjU2LjQ2QS42My42MywwLDAsMSwzLjI2LDZhMS4yNCwxLjI0LDAsMCwxLS4xOS0uNTFsLS4yLTFaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBhdGggZD0iTTQuNjcsNC4xbC4yNCwwLC4zMSwxLjY0LjkxLS4xOCwwLC4yMkw1LDZaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBvbHlsaW5lIHBvaW50cz0iMTQuNzMgMTQuMDYgMTQuMTggMTEuMTYgMTcgMTAuNiIgc3R5bGU9ImZpbGw6I2NjYyIvPjwvc3ZnPg==');
  }
  &--outlook::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij48dGl0bGU+aWNvbi1vY2FsPC90aXRsZT48cGF0aCBkPSJNNi44Miw0LjM2SDQuNzVWNi41NEg2LjgyWm0yLjgzLDBINy41OFY2LjU0SDkuNjVabTIuNzYsMEgxMC4zNFY2LjU0aDIuMDdabTIuNzYsMEgxMy4wOVY2LjU0aDIuMDdaTTYuODIsNy4xN0g0Ljc1VjkuMzVINi44MlptMi44MywwSDcuNThWOS4zNUg5LjY1Wm0yLjc2LDBIMTAuMzRWOS4zNWgyLjA3Wm0yLjc2LDBIMTMuMDlWOS4zNWgyLjA3Wm0tMTEuMSwwSDJWOS4zNUg0LjA2Wk02LjgyLDEwSDQuNzV2Mi4xOUg2LjgyWm0yLjgzLDBINy41OHYyLjE5SDkuNjVabTIuNzYsMEgxMC4zNHYyLjE5aDIuMDdabTIuNzYsMEgxMy4wOXYyLjE5aDIuMDdaTTQuMDYsMTBIMnYyLjE5SDQuMDZabTIuNzYsMi44MUg0Ljc1VjE1SDYuODJabTIuODMsMEg3LjU4VjE1SDkuNjVabTIuNzYsMEgxMC4zNFYxNWgyLjA3Wm0tOC4zNSwwSDJWMTVINC4wNlpNMTUuODUsMEgxLjE1QTEuMTYsMS4xNiwwLDAsMCwwLDEuMTdWMTUuODNBMS4xNiwxLjE2LDAsMCwwLDEuMTUsMTdoMTQuN0ExLjE2LDEuMTYsMCwwLDAsMTcsMTUuODNWMS4xN0ExLjE2LDEuMTYsMCwwLDAsMTUuODUsMFptLS4wOCwxNS42SDEuMjNWMy43M0gxNS43N1oiIHN0eWxlPSJmaWxsOiM5ZTc1ZmYiLz48L3N2Zz4=');
  }
}

em.frs {
  display: none;
}

.form-changeable-area {
  h6 {
    margin-bottom: 30px;
    margin-top: 50px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }
}

