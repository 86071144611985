/* Карточка курса на главной странице КУРСЫ */

.programs {
  &__meta-wrapper {
    display: flex;
    align-items: center;
    min-height: 60px;
    border-top: 1px solid $color-grey-lines;
    margin-top: 30px;
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
    }
  }

  &__meta {
    margin-top: 0;
    .orange-text {
      margin-right: 20px;
    }
    span {
      margin-right: 14px;
      display: inline-block;
    }
    span:last-child {
      margin-right: 0;
    }
  }

  .material-link__img {
    height: auto;
  }
  &__card {
    width: 100%;
    background-color: white;
    margin-bottom: 30px;
    min-height: 240px;
    padding: 30px 30px 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: none;
    position: relative;
    overflow: hidden;
    will-change: transform;
    transition: 0.2s ease-out;
    @include media-breakpoint-down(xs) {
      padding: 25px 20px 0 25px;
    }
    .programs__desc {
      flex-grow: 1;
      margin-bottom: 0;
      position: relative;
      z-index: 9;
      color: $color-grey-text;
    }
    &:active,
    &:focus {
      border-bottom: none;
    }
    h3 {
      margin-top: 0;
      display: inline-block;
      will-change: transform;
      word-break: break-word;

    }
    &:hover {
      box-shadow: 0 3px 20px 0px $color-grey-lines;
      transform: translateY(-3px);
    }
    &__icon {
      margin-right: 40px;
      flex-shrink: 0;
align-self: baseline;
background-repeat: no-repeat;
background-size: contain;
}
&__inner {
  display: flex;
  justify-content: flex-start;
  &-wrapper--title {
    @include media-breakpoint-down(xs) {
      display: flex;

    }
  }
}
}

.programs__card__icon-mobile {
  @include media-breakpoint-down(xs) {
    align-self: baseline;
    margin-right: 15px;
    flex-shrink: 0;
  }
}


    /* Баннер*/
    &__banner {
    	background-size: cover !important;
    	h3, p {
    		color: white;
    	}
    	&:hover {
    		transform: none;
    	}
    }
    &__tag {
    	text-transform: uppercase;
    	border: 1px solid $color-grey-lines;
    	border-radius: 2px;
    	padding: 1px 7px;
    	margin-top: 0;
    	vertical-align: middle;
    	display: inline-block;
    }
  }


  .programms-ico {
    margin-right: 7px;
    vertical-align: middle;
    &--date {
      width: 13px;
      height: 14px;
    }
    &--time {
      width: 10px;
      height: 14px;
    }
  }


// Кнопка "К актуальным курсам"

.btn-center {
	margin-top: 30px;
	margin-bottom: 30px;
}

/* Цветные полоски как метки курса */

.card-color {
	border-width: 10px 0px 0px 0;
	border-style: solid;
	color: white !important;
}

.marketing {
	border-image: 10 linear-gradient(to right, #ffb05a 0%,#f2db96 100%);
}

.design {
	border-image: 10 linear-gradient(to right, #575bbb 0%,#9579ba 100%);
}

.investment {
	border-image: 10 linear-gradient(to right, #2b88ca 0%,#58e5ff 100%);
}

.development {
	border-image: 10 linear-gradient(to right, #cf6e6c 0%,#e56961 29%,#f7806b 51%,#fb917a 73%,#ffa093 92%);
}

.business {
	border-image: 10 linear-gradient(to right, #87c24e 0%,#b3cb85 100%);
}

.next-season {

  .p--teaser {
    margin-bottom: 40px;
  }
}

.event-card {
  padding-bottom: 30px;
  .programs__meta {
    margin-top: 20px;
  }
}

.programs__card--new {
  border: none;
}

// карточка с промо курсом в сайдбаре

.programs__card--promo {
  box-shadow: 0 1px 0 $color-grey-lines, -1px 0 0 $color-grey-lines, 1px 0 0 $color-grey-lines;
  &:hover {
    transform: none;
    box-shadow: 0 1px 0 $color-grey-lines, -1px 0 0 $color-grey-lines, 1px 0 0 $color-grey-lines;
  }
  h3 {
    display: inline;
    margin-right: 5px;
    transition: 0.05s linear;
  }
  .programs__tag {
    vertical-align: text-bottom;
  }
  &:hover  h3 {
    color: $color-a--hover;
    text-decoration: none;
    box-shadow: 0 1px 0 $color-a--hover;
  }
}

.sidebar {
  height: 100%;
}

.promo-kurs-sidebar {
  position: sticky;
  top: 80px;
}
