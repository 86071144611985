.alert {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 999999;
  font-family: 'Clear Sans Regular';
  font-size: 16px;
  line-height: 26px;
  color: $color-grey-text;
  font-weight: 400;
  margin: 0;
  background-color: white;
  padding: 20px;
  border: 0;
  border-left-width: 80px;
  border-style: solid;
  max-width: 480px;
  box-shadow: 0 0 29px 5px rgba(51, 55, 65, 0.1);
  border-radius: 2px;
  
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 24px;
      max-width: 300px;
      left: 0;
      right: 0;
      margin: auto;
    }
    
    .close {
      float: none;
      position: absolute;
      top: 3px;
      right: 7px;
      font-size: 20px;
      font-weight: 100;
      color: $color-grey-text;
      opacity: 1;
      &:hover {
        color: #2c2d2e;
      }
    }
        
 }
 
 // success
 
 .alert-success {
   border-color: $color-green-light;
   &::before {
     content: '';
     width: 38px;
     height: 38px;
     background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmZmZmYiIHdpZHRoPSIzOCIgaGVpZ2h0PSIzOCIgdmlld0JveD0iMCAwIDM4IDM4Ij48dGl0bGU+aWNvbi1ub3RpZnktc3VjY2VzczwvdGl0bGU+PHBhdGggZD0iTTI2LjQzLDEzLjQzbC05LDktMy45My0zLjkzQTEsMSwwLDAsMCwxMiwyMGw0LjY0LDQuNjRhMSwxLDAsMCwwLC43MS4yOSwxLDEsMCwwLDAsLjcxLS4yOWw5Ljc1LTkuNzVhMSwxLDAsMCwwLTEuNDItMS40MloiLz48cGF0aCBkPSJNMTksMEExOSwxOSwwLDEsMCwzOCwxOSwxOSwxOSwwLDAsMCwxOSwwWm0wLDM2QTE3LDE3LDAsMSwxLDM2LDE5LDE3LDE3LDAsMCwxLDE5LDM2WiIvPjwvc3ZnPg==");
     background-repeat: no-repeat;
     background-size: contain;
     position: absolute;
     left: -59px;
     top: 0;
     bottom: 0;
     margin: auto;
   }
 }
 
 // danger
 
 .alert-danger, .alert-dismissible, .alert-warning {
   border-color: $color-red-light;
   &::before {
     content: '';
     width: 38px;
     height: 31px;
     background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmZmZmYiIHdpZHRoPSIzMyIgaGVpZ2h0PSIyNiIgdmlld0JveD0iMCAwIDMzIDI2Ij48dGl0bGU+aWNvbi1ub3RpZnktZXJyb3I8L3RpdGxlPjxwYXRoIGQ9Ik04LjEzLDkuNTlhMSwxLDAsMCwwLC43MS4zLDEsMSwwLDAsMCwuNzEtLjMsMSwxLDAsMCwwLDAtMS40M0w2LjM1LDQuOTRsMy4yLTMuMjFBMSwxLDAsMCwwLDkuNTUuMywxLDEsMCwwLDAsOC4xMy4zTDQuOTIsMy41MSwxLjcyLjNBMSwxLDAsMCwwLC4yOS4zYTEsMSwwLDAsMCwwLDEuNDNMMy41LDQuOTQuMjksOC4xNkExLDEsMCwwLDAsMSw5Ljg5YTEsMSwwLDAsMCwuNzEtLjNsMy4yLTMuMjJaIi8+PHBhdGggZD0iTTI5LjUsNC45NGwzLjIxLTMuMjFhMSwxLDAsMCwwLDAtMS40MywxLDEsMCwwLDAtMS40MywwbC0zLjIsMy4yMUwyNC44Ny4zYTEsMSwwLDAsMC0xLjQyLDAsMSwxLDAsMCwwLDAsMS40M2wzLjIsMy4yMS0zLjIsMy4yMmExLDEsMCwwLDAsMCwxLjQzLDEsMSwwLDAsMCwuNzEuMywxLDEsMCwwLDAsLjcxLS4zbDMuMjEtMy4yMiwzLjIsMy4yMmExLDEsMCwwLDAsMS40MywwLDEsMSwwLDAsMCwwLTEuNDNaIi8+PHBhdGggZD0iTTE2LjUsMTguMjdBMTQuNjYsMTQuNjYsMCwwLDAsNC42MSwyNC40YTEsMSwwLDEsMCwxLjYzLDEuMTgsMTIuNTksMTIuNTksMCwwLDEsMjAuNTIsMCwxLDEsMCwwLDAsLjgyLjQyLDEsMSwwLDAsMCwuNTgtLjE5LDEsMSwwLDAsMCwuMjMtMS40MUExNC42NiwxNC42NiwwLDAsMCwxNi41LDE4LjI3WiIvPjwvc3ZnPg==");
     background-repeat: no-repeat;
     background-size: contain;
     position: absolute;
     left: -59px;
     top: 0;
     bottom: 0;
     margin: auto;
   }
 }
 
 
 .alert-debt {
   position: relative;
   width: 100%;
   max-width: 100%; 
   top: initial;
   right: inherit;
   margin-bottom: 30px;
   z-index: 0;
   box-shadow: none;
 }
