// анимация на кнопку

.animation-btn {
  position: relative;
  transition: 0.4s linear;
  display: inline-block;
  width: 100%;
}

.animation-btn:not(.disabled):before {
  content: '';
  position: absolute;
  right: 30px;
  top: 51px;
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-left-color: transparent;
  border-bottom-color: white;
  border-right-color: white;
  border-top-color: white;
  border-radius: 50%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  z-index: 30;
}
.sbreg:not(.disabled):after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0px;
  transition-delay: 0.3s;
  transition-duration: 0.75s;
  transition-property: width;
}
.animation-btn.sending sbreg:not(.disabled) {
  pointer-events: none;
  cursor: not-allowed;
}
.sbreg:not(.disabled).sending {
//   text-align: left;
// text-indent: -40px;
}
.animation-btn.sending:before {
  transition-delay: 0.3s;
  transition-duration: 1s;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.animation-btn.sending:after {
  transition-delay: 0s;
  width: 20px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
