.event-reg-anketa {
    .input-field label {
        position: static;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
        &:empty {
          display: none;
        }
    }
    .input-field label.active {
        transform: none;
        color: $color-grey-text;
    }
    .input-field {
        margin-top: 0;
        margin-bottom: 10px;
        position: relative;
        input:not([type="checkbox"]) {
      }
      .text-counter {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 14px;
        color: rgba(137, 153, 169, 0.3);
        @include media-breakpoint-down(xs) {
          font-size: 10px;
          top: 18px;
          right: 9px;
        }
        &--long {
          top: auto;
          bottom: 20%;
        }
      }
    }
    select {
        width: 100%;
        background-color: transparent;
        color: $color-bg;
        appearance: none;
        background-repeat: no-repeat;
        background-position: 97% 50%;
        option {
          color: $color-grey-text;
        }
    }
    	input:not([type]),
    	input[type=date],
    	input[type=datetime-local],
    	input[type=datetime],
    	input[type=email],
    	input[type=number],
    	input[type=password],
    	input[type=search],
    	input[type=tel],
    	input[type=text],
    	input[type=time],
    	input[type=url],
    	textarea,
    	select {
  			background-color: rgba(255,255,255,0.07);
        padding-left: 15px;
        padding-right: 40px;
        border: none;
        box-sizing: border-box;
        @include media-breakpoint-down(xs) {
          padding-right: 15px;
        }
        
      &:focus:not([readonly]) {
			// поле в фокусе
			border: none;
			box-shadow: 0 1px 0 0 $color-orange-medium;
			color: $color-orange-medium;

			& + label:not([for="input-subscribed"]) {
				color: $color-orange-medium;
			}
			&.message-error:focus:not([readonly]) {
  			border: none;
			}
		}
    	}
    	textarea {
      	min-height: 110px;
      	resize: vertical;
    	}
}

.comment {
	color: $color-bg;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 9px;
}

.personal-data .input-field {
  input {
    margin-bottom: 30px !important;
  }
    margin-top: 20px;
}

.personal-data .input-field p.message-error {
  bottom: 10px;
}



