// БЛОК С ПОДБОРКОЙ МЕТРИАЛОВ


// Заголовок блока
.selections + .materials h2 {
	margin-top: 0;
	margin-bottom: 50px;
}

.material-link {
    margin-bottom: 60px;

    &__img {
        display: inline-flex;
        height: 210px;
        overflow: hidden;

        .overlay {
            opacity: 0;
        }

        &__bg {
            height: 100%;
            width: 100%;
            transition: all 0.2s linear;
        }
    }

    &__text {
        &__title {
            display: inline;
        }

        &__teaser {
            margin-top: 25px;
        }

        .divider {
            margin-top: 25px;
        }
    }
    @include media-breakpoint-up (sm) {
        &__img {
            flex: 4;
        }

        &__text {
            flex: 5;

            .author_post &,
            .selection_post & {
                flex: 9;
            }
        }
    }
    @include media-breakpoint-only(xs) {
        flex-direction: column;

        &__text {
            padding-top: 1em;
        }
    }

    &:hover &__text__title {
        @extend a:hover;
        // border-bottom: 1px solid;
    }

    &:hover &__img__bg {
        @include hover-bg-img();
    }

    &:hover {
        .overlay {
            @extend .overlay--hover;
        }
    }
}
