.video-article {
  margin-top: 40px;
  @include media-breakpoint-down(xs) {
    margin-top: 20px;
  }
  iframe {
    width: 100% !important;
    height: 400px !important;
    @include media-breakpoint-down(md) {
      height: 300px !important;
    }
    @include media-breakpoint-down(xs) {
      height: 200px !important;
    }
  }
  .p--further {
    font-size: 14px !important;
    line-height: 22px !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    color: #8999a9 !important;
  }
}