.plus-with-us {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white;
  @include media-breakpoint-down(xs) {
      padding-top: 50px;
      padding-bottom: 50px;
  }
  h2, .edu-title {
    margin-top: 0;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }
}

.infograph {
    &__item {
        padding: 0 15px;
        @include media-breakpoint-down(xs) {
            padding: 0;
            margin-top: 30px;
        }
        .p--normal:first-of-type  {
            margin-top: 10px;
            @include media-breakpoint-down(xs) {
                margin-top: 0;
            }
        }
        .p--normal:nth-child(3) {
            @include media-breakpoint-down(xs) {
                margin-top: 10px;
            }
        }
    }
}

.learned {
  padding-top: 80px;
  @include media-breakpoint-down(xs) {
    padding-top: 50px;
  }
  h2 {
    margin-top: 0;
  }
}

.recalls {
    padding-bottom: 80px;
  @include media-breakpoint-down(xs) {
    padding-bottom: 50px;
  }
}

  .p--teaser--history {
    margin-bottom: 70px;
  }
  
.press-release {
  margin-bottom: 40px;
  .p--small {
    margin-top: 30px;
  }
  @include media-breakpoint-down(xs) {
    .p--normal, .p--small {
      margin-top: 20px;
    }
  }
}
