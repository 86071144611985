.index-page {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      height: auto;
      padding-bottom: 40px;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(40,44,54,0.8);
        z-index: 9;
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh;
        @include media-breakpoint-down(xs) {
          height: auto;
          padding-top: 60px;
        }
    }
    &__title {
        color: white;
        text-shadow: 0px 0px 7px rgba(40, 44, 54, 0.5);
        text-align: center;
        line-height: 75px;
        position: relative;
        text-transform: uppercase;
        z-index: 9;
        @include media-breakpoint-down(xs) {
            line-height: 40px;
        }
        a {
            // border-bottom: none;
            transition: 0.1s ease-out;
            box-shadow: 0 2px 0 $color-a;
            // border-bottom-width: 2px;
            // border-bottom-style: solid;
            // border-bottom-color: $color-a;
            @include media-breakpoint-down(xs) {
                box-shadow: 0 1px 0 $color-a;
            }
            &:hover {
                // border-bottom: none;
                box-shadow: none;
                // border-bottom-color: transparent;
            }
        }
    }
    .p--mega {
        color: white;
        text-shadow: 0px 0px 7px rgba(40, 44, 54, 0.5);
        text-align: center;
        margin-top: 10px;
        position: relative;
        z-index: 9;
        @include media-breakpoint-down(xs) {
            line-height: 25px;
        }
    }


}

// Новость дня

.day-news-wrapper {
  position: absolute;
  bottom: 40px;
  z-index: 9;
  width: 100%;
  @include media-breakpoint-down(xs) {
    position: relative;
    bottom: auto;
  }
}

.day-news {

  display: block;
  border: 1px solid rgba(210, 224, 230, 0.122);
  padding: 15px 15px;
  @include media-breakpoint-down(xs) {
    margin-top: 30px;
  }
  &__title {
    margin-top: 0;
    color: white;
  }
  &__text {
    margin-top: 10px;
    @include media-breakpoint-down(xs) {
      margin-top: 7px;
      font-size: 12px;
      line-height: 17px;
    }
  }
  &:hover {
    box-shadow: none;
    background-color: rgba(210, 224, 230, 0.122);
  }
}
