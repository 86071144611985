.events-info {
    width: 100%;
    height: auto;
    padding: 25px 20px;
    border: 1px solid $color-orange;
    margin-bottom: 40px;
    .cta-button {
        width: 100%;
        margin-top: 10px;
    }
    &__date {
        font-family: $font-bold;
        color: $color-grey-dark;
        text-transform: uppercase;
        margin: 0;
    }
    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }
    p {
        margin: 0;
    }
}

.event-content {
    .reg-button {
        text-align: center;
    }
    .two-blocks-container {
        margin-top: 50px;
    }
}

.reg-closed {
    color: $color-orange;
}

.registration__date--closed {
  display: block !important;
  margin: 0 !important;
}


// Icons

.events-info {
    .icon {
        margin-right: 20px;
        width: 28px;
        height: 28px;
        fill: $color-grey-interface;
        flex-shrink: 0;
    }
}
