.author-columns {
	background: #fff;
	margin-top: 0;
	margin-bottom: 40px;
	padding: 25px 20px;
	h5 {
		margin-top: 0;
	}
	&__column {
		margin-top: 25px;
		margin-bottom: 40px;
		display: flex;
		position: relative;
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0;
			bottom: -12px;
			pointer-events: none;
			border-bottom: 1px solid $color-grey-lines;
		}


		&:last-child {
			margin-bottom: 0;

			&:after {
				border-bottom-color: transparent;
			}
		}


		&__img {
			width: 45px;
			min-width: 45px;

			height: 45px;
			min-height: 45px;
		}


		&__text {

			padding-left: 20/$rem;


			&__fio {
				@include media-breakpoint-down(sm) {
					line-height: 0.6em;
				}
			}


			&__title {
				margin-top: 0.2em;
			}

		}

		&:hover &__text * {
			@include a--hovered--underline();
		}


	}

}
