
.sub-items {
  transition: top 400ms , box-shadow 1200ms, transform 400ms 500ms;
  padding-left: 0;
  line-height: 1.4;
  list-style-type: none;
  text-align: center;
  margin-top: 0;

  &__item > a {
    width: 100%;
    display: inline-block;
    padding: 6px 30px 7px;
    height: auto;
    font-size: 16px;
    text-transform: none;
    @include media-breakpoint-down(md) {
        white-space: normal;
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: 15px;
    display: none;

    &__item > a {
      color: $color-menu-item;

      &:hover {
        color: $color-a--hover;
      }
    }
  } /* end md */

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 100;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 70px;
    left: 50%;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transform: translateY(15px) translateX(-50%);
    transition: 0.3s ease 0.3s;

    &__item > a {
      &:hover {
        text-shadow: none;
        background-color: $color-bg;

        span {
          color: $color-blue;
          border-bottom: 1px solid $color-blue;
        }
      }
    }

    &::before {
      content: '';
      display: block;
      background: white;
      position: absolute;
      top: -6px;
      left: 0;
      right: 0;
      margin: auto;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
    }
  } /* end lg */
} /* end .sub-items */


// псевдоэлемент submenu для авторизованного юзера
@include media-breakpoint-up(lg) {
  .menu__user .sub-items {
    left: auto;
    right: 0;
    transform: translateY(15px) translateX(0);
    &::before {
      right: 29px;
      left: initial;
    }
  }
}


// hover для submenu
.menu__user:hover .sub-items {
  visibility: visible;
  opacity: 1;
  top: 70px;
  pointer-events: all;
  transform: translateY(0) translateX(0);
  transition-delay: 0s;
  box-shadow: 0 0 40px rgba($color-grey-verydark, 0.3);
}

.menu__items__item--with-dropdown:hover .sub-items {
  visibility: visible;
  opacity: 1;
  top: 70px;
  pointer-events: all;
  transform: translateY(0) translateX(-50%);
  transition-delay: 0s;
  box-shadow: 0 0 40px rgba($color-grey-verydark, 0.3);
}


//submenu на мобильном
/*
@include media-breakpoint-down(md) {
    .sub-items--visible {
      display: block;
      padding-top: 15px;
      transform: initial !important;
      box-shadow: initial !important;
      top: initial !important;
    }
}

.svg--elem-arrowdown {
    transition: 0.3s linear;
    will-change: transform;
    &--up {
        transform: rotate(180deg);
    }
}
*/

@include media-breakpoint-down(md) {
    .sub-items {
      display: block;
      padding-top: 15px;
      transform: initial !important;
      box-shadow: initial !important;
      top: initial !important;
    }
    .svg--elem-arrowdown {
      display: none !important;
    }
    .nav-up {
      transform: translateY(-100%);
    }
    .menu {
      transition: 0.3s ease-out;
    }
}

// .svg--elem {
//     transition: 0.3s linear;
//     will-change: transform;
//     &--up {
//         transform: rotate(180deg);
//     }
// }

// .mobile-menu__item .sub-items {
//     @include media-breakpoint-down(lg) {
//         display: block;
//         padding-top: 15px;
//         transform: initial !important;
//         box-shadow: initial !important;
//         top: initial !important;
//     }
// }

.menu__right-part .sub-items__item > a {
  white-space: inherit;
}
