// для картинок в блоге

center img {
  max-width: 100%;
}

.landing-header .menu__items__item:last-child {
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.page-cover--event-old {
  min-height: 29vw;
  background-size: cover !important;
  background-position-x: -1vw !important;
  @include media-breakpoint-down(xs) {
    min-height: 160px;
    background-size: cover!important;
    background-position-x: 98% !important;
  }
}

.content-inner-wrapper {
  @include media-breakpoint-down(xs) {
    padding-top: 60px;
  }
}

.for-table {
  table {
      margin-top: 30px;
      @include media-breakpoint-down(xs) {
        width: 100% !important;
      }
    img {
      @include media-breakpoint-down(xs) {
        width: 100px;
      }
    }
     td p, font, span {
       color: #333741 !important;
       @include media-breakpoint-down(xs) {
          font-size: 15px;
          line-height: 24px;
       }
     }
     td {
       span {
         font-family: Clear Sans Bold;
       }
       @include media-breakpoint-down(xs) {
         display: flex;
         flex-direction: column;
       }
     }
  }
}


// Для таблиц в старых статьях

table {
  margin-top: 30px !important;
  width: 100% !important;
  border-collapse: collapse;
  border: 1px solid $color-grey-lines;
  height: auto !important;
  position: relative;
  margin-bottom: 20px;
/*
  @include media-breakpoint-down(xs) {
    &::before {
      content: 'Если не видна вся информация, используйте прокрутку вправо →';
      font-size: 10px;
      display: block;
      color: $color-red;
      padding: 2px 5px;
      position: absolute;
      top: -30px;
      left: 0;
      width: 80vw;
    }
  }
*/
    tr {
      height: auto !important;
    }
    td, th {
  border: 1px solid $color-grey-lines;
  padding: 10px;
  font-family: 'Clear Sans Regular';
  font-size: 16px;
  line-height: 26px;
  color: $color-text;
  font-weight: 400;
  height: auto !important;
  @include media-breakpoint-down(sm) {
    padding: 5px;
  }
  }
}

table td, table p {
  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
    line-height: 1.4 !important;
  }
}

// Прибиваем footer

main {
    min-height: calc(100vh - 498px);
}


// всплывающие сообщения об ошибке

.alert.alert-window.alert-info {
//     border-radius: 0;
//     background-color: $color-bg;
//     color: $color-red;
//     padding-top: 15px;
//     padding-bottom: 15px;
//     border: none;
//     font-size: 14px;
}


.article__text {
    .block--text:first-child,
    .block--note:first-child,
    .block--quote:first-child,
    .block--image-small:first-child,
    .block--tweet:first-child,
    .block--conclusions:first-child,
    .block--person:first-child,
    .block--question-answer:first-child,
    h3:first-child {
        margin-top: 0;
}
    .block--conclusions *,
    .block--text *,
    .block--quote *,
    .block--image-small *,
    .block--tweet *,
    .block--person *,
    .block--question-answer * {
        &:first-child {
            margin-top: 0;
        }
    } 
}

.media-center {
    padding-bottom: 50px;
}

.input {
    border-radius: 0;
}

.all-title {
    @include media-breakpoint-down(md) {
        margin-bottom: 50px;
    }
    h2 {
        margin-bottom: 50px;
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
    }
    h4 {
        margin-bottom: 30px;
    }
}

body {
  font-family: 'Clear Sans Regular';
  background-color: $color-bg;
}

.body {
  &--content {
    background-color: #fff;
  }
}

.table td,
.table th {
  padding: 0;
  border: none;
}

.divider {
  display: block;
  width: 33px;
  height: 3px;
  margin-top: 20px;
  background-color: #d2e0e6;
}

.divider--accent {
  width: 41px;
  height: 7px;
  background-color: $color-orange-medium;
}

.divider--single {
  margin-top: 40px;
}

.utils-square {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.utils-bg-img {
  background-color: transparent;
  background-position: center;
  background-size: cover;
}

.utils-cycle {
  border-radius: 50%;
}

.utils-tbl-cell {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

.utils-tbl {
  display: table;
  width: 100%;
  float: none;
}

.utils-a-with-img {
  display: block;
  width: auto;
  height: auto;
}

.utils-img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.-is-visible {
  display: block;
}

.two-blocks-container {
  margin-top: 15px;
  align-items: flex-start;
  justify-content: space-between;
}

.border-gradient {
  $second-color: #ff4800;
  border-image: linear-gradient(to right, $color-orange, $second-color) 1 100%;
}

.bg-gradient {
  $second-color: #ff4800;
  background-color: $color-orange;
  background-image: linear-gradient(to right, $color-orange, $second-color);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-grey-verydark;
  opacity: 0.5;
  transition: all 0.2s linear;
  will-change: gradient-position;
  backface-visibility: hidden;
}

.overlay--hover {
  $second-color: #ff4800;
  @include x-multiple-colored-gradient( 'left', $second-color 0%, $color-orange 50%, $second-color 100%);
  opacity: 0.7;
  background-color: $color-orange;
  background-size: 200% auto;
  background-position: 100% 0;
  animation: gradient-position 1.4s ease infinite;
}

/* TODO: delete*/
#carrotquest-messenger-collapsed.carrotquest-messenger-right_bottom {
  margin-bottom: 60px !important;
}

@-webkit-keyframes gradient-position {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

@keyframes gradient-position {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}


.account-banner-wrapper {
  background-color: $color-grey-verydark;
  position: relative;
  z-index: 99;
  padding: 20px 0;
  min-height: 100px;
  transition: 0.5s linear;
  margin-bottom: -10px;
   @include media-breakpoint-down(xs) {
     padding: 30px 0;
   }
  &--hide {
    display: none;
  }
}
.account-banner {
  text-align: center;
  a {
    box-shadow: 0 1px 0 $color-a--hover;
    &:hover {
      box-shadow: none;
    }
  }
  .p--small {
    color: white;
  }
  p {
    margin: 0;
  }
  &__close {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 0;
    color: $color-bg;
    font-weight: 100;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    width: 15px;
    height: 15px;
     @include media-breakpoint-down(xs) {
       right: 10px;
       top: 10px;
       bottom: auto;
     }
     &::before {
       content: '';
       width: 1px;
       height: 15px;
       background-color: $color-bg;
       transform: rotate(-45deg);
       position: absolute;
       top: 0;
       left: 7px;
     }
     &::after {
       content: '';
       width: 1px;
       height: 15px;
       background-color: $color-bg;
       transform: rotate(45deg);
       position: absolute;
       top: 0;
       left: 7px;
     }
  }
}

// Страница успешной покупки курса

.pay-success {
  padding-bottom: 40px;
  .cta-button {
    margin-top: 50px;
  }
}

// для блока с маленькой картинкой

.block--image-small {
    p {
      font-family: 'Clear Sans Regular';
      font-size: 14px !important;
      line-height: 22px !important;
      margin-top: 15px !important;
      margin-bottom: 0 !important;
      color: $color-grey-text !important;
      text-transform: none !important;
      font-weight: 400 !important;
      @include media-breakpoint-only(xs) {
          font-size: 13px !important;
          line-height: 20px !important;
      }
      a,
      a:active,
      a:focus,
      a:visited {
        color: $color-a !important;
        transition: 0.05s linear !important;
        text-decoration: none !important;
      }
      
      a:hover {
        position: relative !important;
        color: $color-a--hover !important;
        text-decoration: none !important;
        box-shadow: 0 1px 0 $color-a--hover !important;
      }
    }
}
