.footer {
	$margin-between-items: 25px;
	padding-top: 35px - $margin-between-items;
	padding-bottom: 35px;
	background-color: $color-grey-dark;
	color: $color-grey-text;

	a {
		color: inherit;

		&:hover {
			color: $color-a--hover;
		}
	}

	&__copyright {
		margin-top: $margin-between-items;

		p {
			color: inherit;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&__links {
		margin-top: $margin-between-items;

		&__link {
			margin-right: 26px;
			opacity: 0.7;

			&:hover {
				opacity: 1;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&-menu {
		background-color: $color-grey-verydark;
		padding-bottom: 50px;

		h6 {
			color: $color-grey-text;
		}

		a {
			color: inherit;

			&:hover {
				color: $color-a--hover;
			}
		}

		&__header {
			padding-top: 35px;
			padding-bottom: 35px;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			color: $color-grey-text;
			border-bottom: 1px solid $color-grey-dark;
		  font-weight: 400;
      font-family: Clear Sans Regular;

			&__logo {
				display: inline-block;
				margin-right: 20px;
				width: 78px;
				height: 23px;
				border-bottom: 0 !important;
			}

			&__social-links {
				display: flex;
				@include media-breakpoint-down(xs) {
					margin-top: 25px;
				}
				@include media-breakpoint-up(sm) {
					text-align: right;
				}

				.social-links,
				span {
					display: flex;
					// margin-bottom: -3px;
					justify-content: space-between;
					@include media-breakpoint-down(xs) {
						justify-content: initial;
					}
					/* by eye*/
				}

				span {
					padding-top: 10px;
					white-space: nowrap;
					font-size: 14px;
					line-height: 20px;
					display: inline-block;
          font-weight: 400;
          font-family: Clear Sans Regular;
					@include media-breakpoint-down(xs) {
						margin-bottom: 20px;
					}
					/* by eye*/
				}

				span:after {
					content: '';
					display: block;
					margin-right: 15px;
				}
			}

			.social-img {
				width: 38px;
				height: 38px;
				background: no-repeat center;
				margin-right: 9px;
				display: inline-block;
				border-bottom: 0;
				transition: opacity 0.2s ease;
				flex-shrink: 0;
				@include media-breakpoint-down(xs) {
					margin-right: 15px;
				}

				&:hover {
					cursor: pointer;
					opacity: 0.7;
				}

				&:last-child {
					margin: 0;
				}
			}
		}

		&__items {
			margin-top: 50px;

			&__item {
				font-family: 'Clear Sans Light';
				margin-top: 15px;
				line-height: 1.2;
				color: $color-grey-lines;

				&:first-of-type {
					margin-top: 25px;
				}
			}

			&--credential &__title {
				text-transform: none;
				font-size: 14px;
				line-height: 1.4;
				margin-top: 0;
			}
		}
	}
}

.greenfield {
  margin-right: 20px;
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
