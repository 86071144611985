.hosts-events {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 110px;
  padding-bottom: 110px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(38,50,79,0.8) 0%,rgba(40,44,54,0.8) 100%);
    z-index: 0;
  }
  > * {
    position: relative;
    z-index: 9;
  }
  .event-card {
    margin-top: 60px;
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
    &:hover {
      box-shadow: none;
    }

  }
  h2 {
    color: white;
  }
}