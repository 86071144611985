/* Заголовок и статичные блоки на странице "Курсы"*/

.edu-title {
    @include media-breakpoint-only(xs) {
        margin-bottom: 20px;
    }
    h2 {
        margin-bottom: 50px;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        @include media-breakpoint-down(xs) {
            margin-bottom: 30px;
        }
        .p--big {
            line-height: 24px;
            margin-left: 15px;
            margin-top: 0;
            margin-bottom: 0;
            color: $color-grey-text;
        }
    }
    &__icon {
      width: 45px;
      height: 45px;
      text-align: center;
    }
  }

