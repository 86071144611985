
$color-orange: #ff7800;
$color-orange-medium: #ff8730;
$color-orange-light: #f9cda6;

$color-red: #da1b1b;
$color-red-medium: #f23535;
$color-red-light: #faa4a4;

$color-green: #58bc3a;
$color-green-medium: #75d658;
$color-green-light: #c0ecb3;

$color-blue: #209fd5;
$color-blue-medium: #3cbaf0;
$color-blue-light: #98d8f3;

$color-grey-text: #8999a9;
$color-grey-text-dark: #657585;


$color-grey-interface: #a0b4be;
$color-grey-interface--light: #aabdc6;

$color-grey-lines: #d2e0e6;

$color-bg: #f2f5f9;

$color-grey-dark: #333741;
$color-grey-verydark: #282c36; // тень

$color--fb: #2d5096;
$color--vk: #3f6ea1;
$color--instgrm: radial-gradient(ellipse at 70% 70%, #ee583f 8%, #d92d77 42%, #bd3381 58%);
$color--tw: #5aadeb;
$color--g: #e85644;
$color--youtube: #d42422;



/**************  ! Website's colors  **************/

$font-size: 16;
$rem: $font-size/1rem;


$padding-left: 50px;
$margin-top-default: 0;


$color-a: $color-blue;
$color-a--hover: $color-blue-medium;


$color-text: $color-grey-dark;
$color-text--light: $color-bg;


$color-shadow-button: rgba(#000, .25);



$color-menu-item: #fff;
$menu-margin-between: 20px;


// Fonts Site

$font-regular: 'Clear Sans Regular';
$font-bold: 'Clear Sans Bold';
$font-light: 'Clear Sans Light';
