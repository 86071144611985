.block--person {
    margin-bottom: 45px;
    margin-top: 45px;
    &__img {
        max-width: 200px;
        max-height: 200px;
        width: 100%;
        height: 100%;
        @include media-breakpoint-only(xs) {
            margin: 0 auto;
            margin-bottom: 30px;
        }
    }
    &__title {
        font-family: 'Clear Sans Bold';
        margin-top: -10px !important;
        @include media-breakpoint-down(xs) {
            padding-left: 0;
        }
    }
    &__text {
        margin-top: 15px;
         @include media-breakpoint-down(xs) {
             padding-left: 0;
         }
    }
}
