$color-txt: $color-bg;

.login-wrapper {}

.login {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	background-color: $color-grey-verydark;
	pointer-events: all;

	&--with-blur-img {
		background-color: #000;
		@include media-breakpoint-down(sm) {
			background-color: $color-grey-verydark;
		}
	}

	&__blur-img {
		@include media-breakpoint-up(md) {
			position: absolute;
			pointer-events: none;
			top: -5%;
			bottom: -5%;
			left: -5%;
			right: -5%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius= '15');
			filter: blur(15px) opacity(0.3);
			z-index: -1;
		}
	}

	&--with-blur-img &__blur-img {
		@include media-breakpoint-up(md) {
			display: block;
		}
	}

	&__close {
		position: fixed;
		top: 25px;
		right: 42px;
		width: 22px;
		height: 22px;
		cursor: pointer;
		pointer-events: all;
		z-index: 150;
		transition: 0.2s linear;
		@include media-breakpoint-down(xs) {
  		right: 20px;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	&__scroll {
		height: 100%;
		width: 100%;
		overflow-y: auto;
	}

	.container {
		padding-top: 45px;
		padding-bottom: 70px;
		@include media-breakpoint-only(xs) {
			width: 100%;
		}
	}

	h2 {
		color: $color-txt;
		margin-bottom: 45px;
	}

	&__description {
		color: $color-txt;
		margin-top: 45px;
		margin-bottom: 30px;
	}

	.cta-button {
		margin-top: 35px;
	}

	&__hint {
		color: $color-txt;
	}

	&__footer {
		margin-top: 30px;
		color: $color-grey-text;
		text-align: center;

		p {
			color: inherit;
		}
	}

	&__link {
		color: $color-grey-text !important;
		box-shadow: 0 1px 0 $color-grey-text;
		&:hover {
			box-shadow: 0 1px 0 $color-blue-medium;
			color: $color-blue-medium !important;
		}

		&--accent {
			color: $color-a !important;
			box-shadow: 0 1px 0 $color-a;
			&:hover {
				color: $color-blue-medium !important;
				box-shadow: 0 1px 0 $color-blue-medium;
			}
		}
	}

	.p--small {
		color: $color-grey-text;
	}

	.soc_icons {
		margin-bottom: 45px;
	}

	.social-img {
		display: inline-block;
		margin-top: 15px;
		margin-right: 9px;
		width: 60px;
		height: 60px;

		&:hover {
			cursor: pointer;
		}
	}
}

.input-field,
.personal-data .input-field {
	position: relative;
	margin-top: 20px;

	input:not([type]),
	input[type=date],
	input[type=datetime-local],
	input[type=datetime],
	input[type=email],
	input[type=number],
	input[type=password],
	input[type=search],
	input[type=tel],
	input[type=text],
	input[type=time],
	input[type=url],
	textarea,
	select {
		height: 45px;
		width: 100%;
		margin: 0 0 20px;
		padding: 0;
		font-size: 18px;
		line-height: normal;
		color: $color-txt;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid rgba($color-grey-interface--light, 0.3);
		border-radius: 0;
		outline: none;
		box-shadow: none;
		box-sizing: content-box;
		transition: all 0.3s;
		@include media-breakpoint-only(xs) {
			font-size: 16px;
		}

		&:focus:not([readonly]) {
			// поле в фокусе
			border-bottom: 1px solid $color-orange-medium;
			box-shadow: 0 1px 0 0 $color-orange-medium;
			color: $color-orange-medium;

			& + label:not([for="input-subscribed"]) {
				color: $color-orange-medium;
			}
		}

		&.message-error {
			// поле с ошибкой
			border-bottom: 1px solid $color-red-medium;

			& + label:not([for="input-subscribed"]) {
				color: $color-red-medium;
			}
		} 

		&.message-error:focus:not([readonly]) {
			// поле с ошибкой в фокусе
			border-bottom: 1px solid $color-red-medium;
			box-shadow: 0 1px 0 0 $color-red-medium;

			& + label:not([for="input-subscribed"]) {
				color: $color-red-medium;
			}
		}
	}
	/* Change autocomplete styles in WebKit */
	input:-webkit-autofill,
	input:-webkit-autofill:active,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:hover {
		color: $color-orange-medium !important;
		-webkit-text-fill-color: $color-orange-medium;
		transition: background-color 10000s ease-in-out 0s;
	}

	textarea {
		padding: 10px 0 20px;
		min-height: 45px;
//  		resize: none;
	}

	label:not([for="input-subscribed"]) {
		position: absolute;
		left: 0;
		top: 10px;
		width: 100%;
		font-size: 18px;
		color: $color-grey-text;
		cursor: text;
		transition: 0.2s ease-out;

		&.active {
			font-size: 12px;
			color: rgba($color-grey-text, 0.5);
			transform: translateY(-140%);
		}
	}

	input:-webkit-autofill + label {
		font-size: 12px;
		color: rgba($color-grey-text, 0.5);
		transform: translateY(-140%);
	}

	p.message-error {
		margin-top: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		color: $color-red-medium;
		font-size: 12px;
		margin-bottom: 0;
		transition: 0.2s opacity ease-out, 0.2s color ease-out;
	}

	&.validate + label:not([for="input-subscribed"]) {
		// обычное состояние лэйбла
		pointer-events: none;
		width: 100%;
	}
}

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: $color-grey-text;
  }
  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: $color-grey-text;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: $color-grey-text;
  }

