.map-1 {
    height: 300px;
    margin-top: 60px;
}

.map-2 {
    height: 440px;
}

// .map {
//     .contact-map .ymaps-layers-pane ymaps[unselectable=on],
//     .filials-map .ymaps-layers-pane ymaps[unselectable=on],
//     canvas {
//         border-radius: 7px;
//         -webkit-filter: grayscale(100%);
//         filter: grayscale(100%);
//     }
//
//     #map .ymaps-copyright-agreement-black,
//     #map .ymaps-copyright-agreement-black a,
//     #map .ymaps-copyright-legend-element-black,
//     #map .ymaps-logo-link-en,
//     #map .ymaps-logo-link-ru {
//         display: none;
//     }
//
//     .contact-map .ymaps-layers-pane ymaps[unselectable=on],
//     .filials-map .ymaps-layers-pane ymaps[unselectable=on],
//     canvas {
//         filter: grayscale(100%) !important;
//         -webkit-filter: grayscale(100%);
//     }
//
// }

.map-wrapper {
    position: relative;
    padding: 60px 0;
    padding-bottom: 50px;
    height: 300px;
    overflow-x: hidden;
    .map {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &--2 {
        height: 440px;
    }
}

.map-contact {
    height: auto;
    padding: 40px 30px;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 90;
    @include media-breakpoint-down(xs) {
      padding: 15px;
    }
    &__email {
        font-family: $font-bold;
        border: none;
        margin-bottom: 20px;
        display: inline-block;
    }
    &__phone {
        font-family: $font-bold;
        color: $color-grey-dark;
        font-size: 25px;
        line-height: 30px;
        display: block;
        margin-bottom: 20px;
        &:hover {
            color: $color-grey-dark;
            border-bottom: none;
            box-shadow: none;
        }
    }
    .cta-button {
        width: 100%;
        min-width: initial;
    }
    .p--normal {
        margin-bottom: 0;
        margin-top: 0;
    }
}
