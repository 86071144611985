.manager {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	height: auto;
	white-space: nowrap;
	text-align: left;
	@include media-breakpoint-down(md) {
		height: 70px;
    display: flex;
    align-items: center
	}
	@include media-breakpoint-down(xs) {
		height: 60px;
	}

	&__img {
		display: none;
	}

	&__info {
		&__who {
			display: none;
		}
	}

	&__info {
		display: inline-block;
		vertical-align: top;
		color: $color-text--light;

		a {
			line-height: 1;
			color: inherit;

			&:focus,
			&:hover {
				color: $color-a--hover;
				border-bottom-color: inherit;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		position: relative;
		padding-right: $menu-margin-between;
		height: 40px;
		transform: none;
		left: auto;

		&__img {
			width: 40px;
			height: 40px;
			display: inline-block;
			margin-right: 15px;
			background-color: $color-orange-medium;
		}

		&__info {
			&__who {
				display: block;
				margin-top: 0;
				color: inherit;
			}
		}

		&:after {
			content: '';
			border-right: 2px solid rgba(#fff, 0.1);
			height: 32px;
			position: absolute;
			right: 0;
			top: 4px;
			/* ( 40px [img] - 32px [divider] ) / 2*/
		}
	}
}

.special-width {
	max-width: 20%;
	min-width: 20%;
}

.flex {
	display: flex;
	align-items: center;
	height: 100px;
}
$flex-items-per-line-list: 1 2 3 4 5;
$flex-items-per-line-size: xl lg md sm xs;
$prev-size: null;
@each $size in $flex-items-per-line-size {
	 @if map-has-key($container-max-widths, $size) == true and $prev-size == null{
		@include media-breakpoint-up($size) {
			 @each $item in $flex-items-per-line-list{
				.flex-items-#{$size}-per-line-#{$item} {
					min-width: #{100% / $item};
					max-width: #{100% / $item};
				}
			}
		}
	}
	@else if map-has-key($container-max-widths, $size) == true and $prev-size != null {
		 @include media-breakpoint-between($size, $prev-size){
			@each $item in $flex-items-per-line-list {
				.flex-items-#{$size}-per-line-#{$item} {
					min-width: #{100% / $item};
					max-width: #{100% / $item};
				}
			}
		}
	}
	@else if map-has-key($container-max-widths, $size) == false and $prev-size != null {
		 @include media-breakpoint-down($size){
			@each $item in $flex-items-per-line-list {
				.flex-items-#{$size}-per-line-#{$item} {
					min-width: #{100% / $item};
					max-width: #{100% / $item};
				}
			}
		}
	}
	$prev-size: $size;
}

.submenu {
	display: flex;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	align-items: center;
	overflow: hidden;
	will-change: top;
	border-bottom: 1px solid $color-grey-lines;
	background-color: rgba(255, 255, 255, 0.8);
	@include media-breakpoint-down(md) {
		display: none;
	}
	@include media-breakpoint-up(lg) {
		&--fixed {
			position: fixed;
			z-index: 99;
			left: 0;
			right: 0;
			top: 0;
		}
	}

	&__close {
		margin-left: auto;
		// пока убираем
		display: none;
		height: 49px;
		width: 50px;
		opacity: 1;
		/* background: $color-grey-interface url("../img/tceh2/submenu-close.png") no-repeat center;*/
		fill: $color-text--light;
		background-color: $color-grey-interface;
		cursor: pointer;

		&:hover {
			background-color: $color-grey-interface--light;
		}
	}

	&__scroll-progress {
		position: absolute;
		left: 0;
		bottom: -3px;
		height: 3px;
		width: 0;
		background: #ff7700;
		background: -webkit-gradient(left top, right top, color-stop(0%, #ff7700), color-stop(100%, #ff4800));
		background: -webkit-linear-gradient(left, #ff7700 0%, #ff4800 100%);
		background: linear-gradient(to right, #ff7700 0%, #ff4800 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#ff7700', endColorstr='#ff4800', GradientType=1 );
	}
}

.submenu-bottom {
	display: flex;
	width: 100%;
	justify-content: center;

	&__social {
		display: inline-flex;
		height: 28px;
		padding: 0 20px;
		border-left: 1px solid $color-grey-lines;

		&--hidden {
			display: none;
		}
	}
}

.main-content {
	&--before-full-img {
		&:after {
			content: none;
		}
	}
}

.two-blocks-container {
	display: flex;
}

.page-cover {
	position: relative;
	width: 100%;
	min-height: 500px;
	background-position: center;
	background-size: cover;
	@include media-breakpoint-down(xs) {
		min-height: 300px;
	}

	&--video {
		min-height: 600px;
		display: flex;
		align-items: center;

		&__player {
			flex: 1;
		}

		&--with-small-menu {
			padding-top: 70px;
			/* верхнее малое меню и сабменю*/
		}

		&--with-big-menu {
			padding-top: 100px;
			/* верхнее большое меню*/
		}

		&--with-submenu {
			padding-bottom: 50px;
			/* нижнее сабменю*/
		}

		&--grey {
			background-image: none !important;
			background-color: $color-grey-verydark !important;
		}

		.media-player {
			display: none;

			&--show {
				display: block;
			}
		}

		.button-play {
			display: block;

			&--hide {
				display: none;
			}
		}
	}
}

.page-cover--video--with-big-menu.page-cover--video--with-submenu {
	.media-player {
		margin-top: 12px;
		/* на глазок*/
	}
}

.sidebar {
	@include media-breakpoint-only(sm) {
		padding-bottom: 30px;
		@include content-padding();
	}

	&__mobile-hidden {
		margin-top: 40px;
		@include first-text();
	}
}

.container-limit {
	@include media-breakpoint-only(xl) {
		max-width: 1212px;
	}

	&--top {
		padding-top: 70px;
		@include media-breakpoint-down(xs) {
			padding-top: 35px;
		}
	}
}

.article_text {
	margin-top: 60px;
}

.editor-links {
	margin-top: 0;
	margin-bottom: 30px;

	h6 {
		margin-bottom: 30px;
	}
}

.article_bottom {
	margin-top: 40px;
}

.teasers-bottom {
	&_all-blocks {
		margin-top: 40px;
	}
}

.selections,
.teasers-bottom {
	margin-bottom: 70px;
	font-family: 'Clear Sans Regular';
    font-size: 16px;
    line-height: 26px;
    color: $color-text;
    @include media-breakpoint-only(xs) {
        font-size: 15px;
        line-height: 24px;
    }
}

.back-link {
	display: inline-block;
	margin-top: 40px;
}

.more-on-the-topic {
	margin-top: 60px;
	@include media-breakpoint-down(xs) {
		margin-top: 30px;
	}
}

.comments {
	margin-top: 50px;
	margin-bottom: 50px;

	h2 {
		margin-bottom: 30px;
	}
}
