

@font-face {
	font-family:'Clear Sans Bold Italic';
	src: url('../fonts/Clear Sans Bold Italic.eot');
	src: url('../fonts/Clear Sans Bold Italic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Bold Italic.woff') format('woff'),
	url('../fonts/Clear Sans Bold Italic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}

@font-face {
	font-family:'Clear Sans Bold';
	src: url('../fonts/Clear Sans Bold.eot');
	src: url('../fonts/Clear Sans Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Bold.woff') format('woff'),
	url('../fonts/Clear Sans Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;

}

@font-face {
	font-family:'Clear Sans Italic';
	src: url('../fonts/Clear Sans Italic.eot');
	src: url('../fonts/Clear Sans Italic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Italic.woff') format('woff'),
	url('../fonts/Clear Sans Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}

@font-face {
	font-family:'Clear Sans Light';
	src: url('../fonts/Clear Sans Light.eot');
	src: url('../fonts/Clear Sans Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Light.woff') format('woff'),
	url('../fonts/Clear Sans Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}

@font-face {
	font-family:'Clear Sans Medium Italic';
	src: url('../fonts/Clear Sans Medium Italic.eot');
	src: url('../fonts/Clear Sans Medium Italic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Medium Italic.woff') format('woff'),
	url('../fonts/Clear Sans Medium Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}

@font-face {
	font-family:'Clear Sans Medium';
	src: url('../fonts/Clear Sans Medium.eot');
	src: url('../fonts/Clear Sans Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Medium.woff') format('woff'),
	url('../fonts/Clear Sans Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}

@font-face {
	font-family:'Clear Sans Thin';
	src: url('../fonts/Clear Sans Thin.eot');
	src: url('../fonts/Clear Sans Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans Thin.woff') format('woff'),
	url('../fonts/Clear Sans Thin.ttf') format('truetype');
	font-weight: 250;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}

@font-face {
	font-family:'Clear Sans Regular';
	src: url('../fonts/Clear Sans.eot');
	src: url('../fonts/Clear Sans.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Clear Sans.woff') format('woff'),
	url('../fonts/Clear Sans.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FEFF;
}


