
// Валидируем правильность ввода e-mail в форме подписки

.error {
    position: absolute;
    top: 42px;
    left: 5px;
    color: $color-red;
    font-size: 0;
    transition: 0.2s ease-out;
}

.email-input {
    position: absolute;
    top: 7px;
    left: 15px;
    color: $color-grey-interface--light;
    font-size: 16px;
    margin: 0;
}


// .valid-input-email > input[type="email"]:focus + label {
//     opacity: 0;
// }

.valid-input-email > input[type="email"]:valid {
    border: 1px solid $color-green;
}
// .valid-input-email > input[type="email"]:valid + label {
//     opacity: 0;
// }

.valid-input-email > input[type="email"]:invalid:not(:focus):not(:placeholder-shown) {
    border: 1px solid $color-red;
}

// .valid-input-email > input[type="email"]:invalid:not(:focus):not(:placeholder-shown) + label {
//     opacity: 0;
// }

.valid-input-email > input[type="email"]:invalid:not(:focus):not(:placeholder-shown) ~ .error {
    font-size: 10px;
    line-height: 1;
}

.valid-input-email {
    position: relative;
}

.subscription-form--bottom {
    .error {
        top: 75px;
        left: 15px;
    }
    .valid-input-email {
        display: inline-block;
        @include media-breakpoint-down(xs) {
            display: block;
        }
    }
}

:focus::-webkit-input-placeholder { color: transparent }
:focus::-moz-placeholder { color: transparent }
:focus:-moz-placeholder { color: transparent }
:focus:-ms-input-placeholder {  color: transparent }
