.im_images-wrapper {
  height: 180px;
  margin-top: 80px;
    @include media-breakpoint-down(xs) {
      margin-top: 30px;
      height: 100px;
  }
}

.im_images {
  height: 180px;
  position: relative;
}

.im_slide {
	width: 180px !important;
	height: 180px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.im_slide:hover {
	box-shadow: none;
}

.slick-track {
  height: 180px !important;
}

@include media-breakpoint-down(xs) {
  .im_slide {
	width: 100px !important;
	height: 100px;
	}
	
	.slick-track {
  height: 100px !important;
}

.im_images {
  height: 100px;
}
	
}


.arrow-im {
    width: 60px;
    height: 60px;
    background-color: $color-grey-verydark;
    opacity: 0.7;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 20;
    cursor: pointer;
    transition: 0.2s linear;
    @include media-breakpoint-down(xs) {
        width: 30px;
        height: 30px;
    }
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 1;
    }
    &--prev {
        left: 15px;
        &::after {
            content: '';
            width: 25px;
            height: 25px;
            border-top: 8px solid white;
            border-left: 8px solid white;
            transform: rotate(-45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            z-index: 20;
            @include media-breakpoint-down(xs) {
                width: 15px;
                height: 15px;
                right: 5px;
                border-top: 3px solid white;
                border-left: 3px solid white;
            }
        }
    }
    &--next {
        right: 15px;
        &::after {
            content: '';
            width: 25px;
            height: 25px;
            border-top: 8px solid white;
            border-right: 8px solid white;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 15px;
            margin: auto;
            z-index: 20;
            @include media-breakpoint-down(xs) {
                width: 15px;
                height: 15px;
                left: 5px;
                border-top: 3px solid white;
                border-right: 3px solid white;
            }
        }
    }
}
