.title-block .social-panel {
  display: block;
  padding-top: 30/$rem;
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.main-content .social-panel {
  padding-top: 30/$rem;
  padding-bottom: 15/$rem;
}

.two-blocks-container .social-panel {
  padding-top: 0/$rem;
}
