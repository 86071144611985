.partners-logo {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    filter: opacity(0.9);
    @include media-breakpoint-down(xs) {
      height: 60px;
      margin-bottom: 15px;
    }
    img {
        max-width: 80%;
        max-height: 100%;
        @include media-breakpoint-down(xs) {
          max-width: 100%;
        }
    }
    &--additional {
        height: 75px;
        img {
            max-width: 80%;
        }
    }
    &:hover {
      box-shadow: none;
      filter: none;
    }
}
