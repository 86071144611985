.subscription-sidebar {
	background: #fff;
	margin-top: 0;
	padding: 25px 20px;
	margin-bottom: 40px;

	&--bg-grey {
		background-color: $color-bg;
	}
}

.subscription-bottom {
	padding-top: 80px;
	padding-bottom: 80px;
	background-color: #fff;
	border-top: 1px solid $color-grey-lines;
	border-bottom: 1px solid $color-grey-lines;
	text-align: center;
	
	&--margin-top {
  	margin-top: 50px;
	}

	h3 {
		margin-bottom: 35px;
		margin-top: 0;
	}

	input {
		margin-top: 20px;
		@include media-breakpoint-up(md) {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
}

.subscription-form {
	&--conclusions {
		margin-left: 50px;
		display: flex;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			margin-left: 0;
		}

		.input {
			// margin-top: 25px;
			@include media-breakpoint-up(md) {
				margin-right: 15px;
			}
		}

		.cta-button {
			// margin-top: 25px;
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
		}
	}

	&--sidebar {
		margin-top: 20px;

		h6 + p {
			margin-top: 20px;
		}

		.input {
			min-width: 0;
			width: 100%;
			@include media-breakpoint-up(md) {
				margin-right: 15px;
			}
		}

		.cta-button {
			min-width: 0;
			width: 100%;
			margin-top: 20px;
		}
	}
}

.article-form {
  .subscription-form--conclusions {
    margin-left: 0;
    margin-top: 25px;
    justify-content: center;
  }
}
