.overflow-hidden {
	overflow: hidden;
}

.menu {
	height: 70px;
	width: 100%;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	background-color: $color-grey-dark;
	will-change: transform, height, background-color;
	transition: height 0.4s, background-color 0.4s, transform 0s;
	&--relative {
		position: relative;
		margin-bottom: -70px;
		@include media-breakpoint-down(xs) {
			margin-bottom: -60px;
		}
	}
	&--small-js {
		position: fixed;
	}
	@include media-breakpoint-down(xs) {
		height: 60px;
	}  
	
	a {
		&:hover {
			box-shadow: none;
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%; 
	}

	&__logo {
		position: relative;
		width: 78px;
		height: 23px;
		@include media-breakpoint-only(xs) {
			width: 54px;
			height: 16px;
		}
		@include media-breakpoint-up(lg) {
			margin-right: $menu-margin-between*2;

			&:after {
				content: '';
				border-right: 2px solid rgba(#fff, 0.1);
				height: 32px;
				position: absolute;
				right: -20px;
				top: (23px - 32px) / 2;
			}
		}
	}

	.mob-menu-wrap {
		display: none;
		height: auto;

		&--open {
			display: block;
		}
	}
	$color-a--active: $color-orange-medium;

	&__items {
		position: absolute;
		top: 60px;
		left: 0;
		-webkit-overflow-scrolling: touch;
		width: 100%;
		height: calc(100vh - 60px);
		padding-top: 25px;
		margin-bottom: 0;
		color: $color-menu-item;

		&__item {
			padding-top: 10px;
			padding-bottom: 10px;
			color: inherit;

			> a {
				display: block;
				width: 100%;
				line-height: 1.3;
				font-size: 28px;
				color: $color-menu-item;
				fill: $color-menu-item;
				text-align: center;
				white-space: normal;

				&:focus,
				&:hover {
					text-decoration: none;
					outline: none;
					color: $color-a--hover;
					fill: $color-a--hover;
				}
			}

			&--active {
				color: $color-a--active;

				> a {
					color: inherit;
					font-family: 'Clear Sans Bold';
				}

				&:hover > a {
					color: $color-a--active;
				}
			}

			&--with-dropdown {
				position: relative;
				cursor: pointer;

				.svg--elem-arrowdown {
					display: inline-flex;
					vertical-align: middle;
					margin-left: 5px;

					&--up {
						transform: rotate(180deg);
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			background-color: $color-grey-verydark;
			opacity: 0;
			pointer-events: none;
			transition: 0.2s ease-out;
			padding-left: 0;
			list-style: none;

			&--visible {
				opacity: 1;
				pointer-events: all;
				z-index: 99999;
				position: fixed;
				overflow: auto;
				padding-bottom: 25px;
				margin-top: 0;
			}

			&__item {
				&--profile {
					.menu--authorized & {
						display: block;
					}
				}
			}
		}
		@include media-breakpoint-up(lg) {
			display: block;
			position: relative;
			top: 0;
			padding-top: 0;
			width: auto;
			height: auto;
			background-color: transparent;
			margin-bottom: 0;
			margin-top: 0;
			list-style-type: none;
			padding-left: 0;

			&__item {
				display: inline-block;
				margin-right: $menu-margin-between - 3px;
				padding-top: 0;
				padding-bottom: 0;
				background-color: transparent;

				&:last-child {
					display: none;
				}

				&:hover {
					> a {
						color: rgba($color-menu-item, 0.5);
						fill: rgba($color-menu-item, 0.5);
					}
				}

				> a {
					display: inline-block;
					width: auto;
					height: 80px;
					line-height: 80px;
					font-size: 14px;
					text-transform: uppercase;
					@include media-breakpoint-down(lg) {
						font-size: 12px;
					}
				}

				&--active:hover {
					> a {
						color: $color-a--active;
					}
				}

				&--with-dropdown {
					> a:after {
						transform: rotate(90deg) scaleX(0.5) translate(1px, -5px);
					}
				}

				&--profile {
					display: none;
				}
			}
		}
		
		&__item {
			@include media-breakpoint-down(lg) {
				margin-right: $menu-margin-between - 10px;
			}
		}
	}

	&__button {
		vertical-align: middle;
		background: transparent;

		&.cta-button {
			display: none;
			color: $color-text--light !important;
			border: 1px solid rgba(#fff, 0.3);

			&:hover {
				border: 1px solid transparent;
				@include bg-gradient();
			}

			&.cta-button--mob {
				width: 290px;
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 120px;
				@include media-breakpoint-up(lg) {
					display: none;
				}
			}
			@include media-breakpoint-up(lg) {
				display: inline-block;
				// width: 130px;
				min-width: 130px;
				height: 40px;
				margin-left: $menu-margin-between;
				font-size: 14px;
			}
		}
	}

	&__items &__button {
		display: inline-block;
		margin-top: 15px;
		width: 230px;
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&__left-part {
		white-space: nowrap;
		display: inline-flex;
		align-items: center;
		height: 100%;
	}

	&__right-part {
		display: inline-flex;
		align-items: center;
		height: 100%;
	}

	&__user {
		display: none;
		align-items: center;
		height: 100%;
		cursor: pointer;
		position: relative;
		@include media-breakpoint-up(lg) {
			display: inline-flex;
			position: relative;
			cursor: pointer;
			margin-left: $menu-margin-between;

			&__img {
				position: relative;
				display: inline-block;
				width: 30px;
				height: 30px;
				background-color: $color-orange-medium;
			}
			color: $color-menu-item;
			fill: $color-menu-item;

			&:hover {
				color: rgba($color-menu-item, 0.5);
				fill: rgba($color-menu-item, 0.5);
			}

			.svg--elem-arrowdown {
				display: inline-flex;
				margin-left: 10px;
			}
		}
	}

	&--authorized &__button {
		display: none;
	}

	&--authorized &__items__item--profile {}

	&--authorized &__user {
		display: inline-flex;
	}

	&__icon {
		width: 28px;
		padding-top: 12px;
		padding-bottom: 12px;
		position: relative;
		display: inline-flex;
		cursor: pointer;
		@include media-breakpoint-down(xs) {
			padding-bottom: 16px;
		}

		span {
			display: inline-block;
			height: 3px;
			width: 28px;
			position: absolute;
			-webkit-transition: 300ms;
			transition: 300ms;
			background: $color-grey-interface;
			border-radius: 8px;
			@include media-breakpoint-up(xl) {
				display: none;
			}

			&:after,
			&:before {
				width: 28px;
				height: 3px;
				display: inline-block;
				background: $color-grey-interface;
				position: absolute;
				content: '';
				transition: 300ms;
				left: 0;
				transform-origin: 0.1071428571rem center;
				border-radius: 8px;
			}

			&:after {
				top: -9px;
			}

			&:before {
				top: 9px;
			}
		}

		&--close {
			span {
				background: 0 0;

				&:after,
				&:before {
					transform: rotate(-45deg);
				}

				&:after {
					transform: rotate(45deg);
				}
			}
		}
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
	@include media-breakpoint-up(lg) {
		@include x-multiple-colored-gradient( 'top', rgba($color-grey-verydark, 0.6) 0%, transparent 100%);
		background-color: transparent;
		height: 80px;

		&--small,
		&--small-js {
			height: 70px;
			background-color: $color-grey-dark;
		}

		&--small &__items &__items__item,
		&--small-js &__items &__items__item {
			> a {
				height: 70px;
				line-height: 70px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		&--absolute {
			position: absolute;
			bottom: 50px;
			/*submenu-height*/
			top: auto;
		}
	}
}

.page-cover .menu--relative {
	margin-bottom: 0;
}

.menu__items__profile {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

//мобильное меню курса

.menu__items {
	&--mobile-visible {
		@include media-breakpoint-up(lg) {
			display: none !important;
		}
	}
	&--mobile-hidden {
		@include media-breakpoint-down(md) {
			display: none !important;
		}
	}
}

