 .error-page {
     height: auto;
     padding-top: 180px;
     padding-bottom: 80px;
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     @include media-breakpoint-down(md) {
         padding-top: 80px;
         padding-bottom: 60px;
     }
     &__content {
         position: relative;
         z-index: 10;
     }
     .programs__card:hover {
         box-shadow: none;
     }
     h1,
     .p--big {
         color: $color-bg;
         text-align: center;
     }
     h2 {
         color: $color-bg;
         margin-bottom: 40px;
     }
     &__content {
       .cta-button--default {
         margin-top: 50px;
         @include media-breakpoint-down(md) {
             margin-top: 20px;
        }
     }
    }
    &--500 {
      height: 100vh;
      @include media-breakpoint-down(md) {
        height: auto;
        padding-top: 180px;
      }
      @include media-breakpoint-down(xs) {
        padding-top: 100px;
      }
    }
 }


.hashtag {
    color: $color-orange-medium;
}

.error-500 {
  color: $color-bg;
  padding-left: 50px;
  margin-bottom: 50px;
  @include media-breakpoint-down(md) {
    padding-left: 0;
    text-align: left;
  }
   h1,
   .p--big,
   .p--mega,
   h4,
   li {
     color: $color-bg;
     text-align: left;
 }
 ul {
   padding-left: 15px;
 }
 h4 {
   margin-top: 50px;
   @include media-breakpoint-down(xs) {
     margin-top: 30px;
   }
 }
 .p--mega {
   margin-top: 40px;
   @include media-breakpoint-down(xs) {
     margin-top: 10px;
     text-align: center;
   }
 }
 h1 {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
 }
 .p--big {
   margin-top: 20px;
    @include media-breakpoint-down(xs) {
      margin-top: 15px;
    }
 }
}

