.g-recaptcha iframe {
    transform: scale(0.8);
    margin-left: -35px;
}


.subscription-bottom {
    position: relative;
    .g-recaptcha {
        position: relative;
        width: 290px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 25px;
        iframe {
            transform: scale(0.8);
            margin: auto;
        }
    }
}
