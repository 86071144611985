//
// @name Input
//
// @description инпуты
//
// @state .input--big - Bigbutton
// @state .input--mega - Megabutton
// @state .input--error - error
//
// @markup
//  <input class="input" placeholder="Трата-та"/>
//

.input {
	display: inline-block;
	width: 100%;
	min-width: 0;
	min-height: 40px;
	@include media-breakpoint-up(md) {
		min-width: 193px;
		width: auto;
	}
	padding: 7px 15px;
	font-size: 16px;
	line-height: 22px;
	border: 1px solid $color-grey-interface--light;
	outline: 0;
	background: #fff;
	color: $color-grey-interface--light;

	&::-webkit-input-placeholder {
		color: $color-grey-interface--light;
	}

	&:-ms-input-placeholder {
		color: $color-grey-interface--light;
	}

	&::-moz-placeholder {
		color: $color-grey-interface--light;
	}

	&:-moz-placeholder {
		color: $color-grey-interface--light;
	}

	&:focus {
		border-color: $color-grey-interface;
		color: $color-grey-interface;
	}

	&--big {
		font-size: 18px;
		line-height: 26px;
		min-height: 55px;
		@include media-breakpoint-up(md) {
			min-width: 240px;
		}
		padding-top: 13px;
		padding-bottom: 14px;
	}

	&--mega {
		font-size: 22px;
		line-height: 30px;
		min-height: 65px;
		@include media-breakpoint-up(md) {
			min-width: 287px;
		}
		padding-top: 16px;
		padding-bottom: 17px;
	}

	&--error {
		border: 1px solid $color-red-medium;
	}
}
//
// @name Input
//
// @description
//
// @markup
// <form action="#0" method="post">
//  <div class="grid-label">
//   <input class="input" id="email1" name="email" type="email"></input>
//   <label for="email1">Email</label>
//  </div>
// </form>
//

form,
.personal-data {
	// > .grid-label {
	// 	position: relative;
	//
	//
	// 	> label {
	// 		opacity: 0.3;
	// 		position: absolute;
	// 		top: 0;
	// 		left: 10px;
	// 	}
	//
	//
	// 	> input[type="email"] {
	// 		&:focus {
	// 			& + label {
	// 				opacity: 0;
	// 			}
	// 		}
	// 	}
	//
	// }
	input[type=checkbox]:not(.payobjects-search input) {
		visibility: hidden;

		& + label {
			@extend .p--small;
			margin-left: 18px;
			margin-top: 0;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 20px;
				height: 20px;
				border-radius: 3px;
				background-color: $color-grey-dark;
			}
		}

		&:checked + label:after {
			content: "";
			position: absolute;
			left: 5px;
			top: 7px;
			width: 10px;
			height: 5px;
			border-bottom: 2.5px solid rgba($color-grey-interface, 0.5);
			border-left: 2.5px solid rgba($color-grey-interface, 0.5);
			transform: rotate(-45deg);
		}
	}
}

label {
  margin-bottom: 5px;
}

.help-text {
  font-size: 14px;
  line-height: 18px;
  color: $color-grey-text;
  margin-top: 0;
  margin-bottom: 10px;
}

