.archive-list {
    margin-bottom: 40px;
    ul {
        list-style: none;
        padding-left: 40px;
        @include media-breakpoint-down(xs) {
            padding-left: 0;
        }
    }
}

.archive-list-month {
    h4 {
        margin-top: 40px;
        margin-bottom: 30px;
    }
}

.archive-list {
    &__item {
        display: flex;
    }

    &__date {
        margin-right: 25px;
        flex-shrink: 0;
        width: 95px;
        @include media-breakpoint-down(xs) {
            margin-right: 10px;
        }
    }
}
