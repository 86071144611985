.share-panel {
  &__item {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 50px;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    white-space: nowrap;
    transition: all 0.4s ease;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      height: 30px;
      width: 1px;
      position: absolute;
      top: 10px;
      right: -1px;
      background-color: $color-grey-lines;
    }

    &--fb:hover {
      background-color: $color--fb;
    }

    &--vk:hover {
      background-color: $color--vk;
    }

    &--tw:hover {
      background-color: $color--tw;
    }

    &:hover {
      .svg svg {
        fill: $color-text--light;
      }
    }

    &:hover &__count {
      color: $color-text--light;
    }

    &--email {
      &:after {
        content: none;
      }

      &__form {
        display: none;

        &--visible {
          display: inline-flex;
        }

        &__input {
          width: 230px;
          margin-right: 7px;
        }

        &__button {
          width: 135px;
          min-width: 135px;
        }
        .g-recaptcha-share iframe {
            transform: scale(0.52);
        }
      }

      &__link {
        font-size: 14px !important;
        line-height: 22px !important;
        margin-top: 1em !important;
        color: $color-grey-text !important;
        text-transform: none !important;
        margin-top: 0 !important;
        @extend a;

        &:hover {
            cursor: pointer;
            box-shadow: 0 1px 0 $color-a--hover;
            color: $color-a--hover !important;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;

      &--email {
        &__form {
          margin-top: 10px;

          &__input {
            width: 150px;
            margin-right: 7px;
          }

          &__button {
            width: 100px;
            min-width: 100px;
          }
        }
      }
    }
  }

  .pocket-btn {
    @extend .share-panel__item;
    @include media-breakpoint-down(xs) {
      &:after {
        content: none;
      }
    }
  }
}
