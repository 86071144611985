.check-email {
  display: flex;
   @include media-breakpoint-down(xs) {
     flex-direction: column;
   }
  img {
    margin-right: 20px;
    width: 50px;
    height: 42px;
    flex-shrink: 0;
    margin-top: 10px;
     @include media-breakpoint-down(xs) {
       margin-bottom: 30px;
       margin-right: 0;
       filter: contrast(70%);
     }
  }
  .login__description {
    margin-top: 0;
  }
}