.consult-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }
  .p--mega {
    display: flex;
    align-items: center;
    margin-right: 45px;
    margin-top: 0;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
    img {
      margin-top: 5px;
      margin-right: 10px;
    }
  } 
  .p--mega:last-child {
    margin-right: 0;
    @include media-breakpoint-down(xs) {
      margin-top: 10px;
    }
  }
}

.consult-form {
  margin-top: 50px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include media-breakpoint-down(xs) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .phone {
    margin-right: 15px;
    @include media-breakpoint-down(xs) {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;
    }
  }
}

.consult-tag {
  .p--small {
    margin-top: 0;
  }
}

.valid-input-email--consult {
  position: relative;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .message-error {
    position: absolute;
    color: red;
    font-size: 12px;
    bottom: -20px;
    left: 10px;
    margin: 0;
  }
}

// Для формы сбоку

.consult-side {
  .consult-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  @media (max-width: 1024px) {
    margin-top: 30px;
    flex-direction: column;
  }
  .p--mega {
    display: flex;
    align-items: center;
    margin-right: 45px;
    margin-top: 0;
    @media (max-width: 1024px) {
      margin-right: 0;
    }
    img {
      margin-top: 5px;
      margin-right: 10px;
    }
  } 
  .p--mega:last-child {
    margin-right: 0;
    @media (max-width: 1024px) {
      margin-top: 10px;
    }
  }
}

.consult-form {
  margin-top: 50px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
 @media (max-width: 1024px) {
    margin-top: 30px;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .phone {
    margin-right: 15px;
    @media (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;
    }
  }
}

.consult-tag {
  .p--small {
    margin-top: 0;
  }
}

.valid-input-email--consult {
  position: relative;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .message-error {
    position: absolute;
    color: red;
    font-size: 12px;
    bottom: -20px;
    left: 10px;
    margin: 0;
  }
}
}



