.relative {
  position: relative;
}

.profile-menu {
  position: sticky;
  top: 90px;
}

%p--small {
  font-family: 'Clear Sans Regular';
  font-size: 14px;
  line-height: 22px;
  color: $color-text;
  margin-top: 16px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
    font-size: 13px;
    line-height: 20px;
  }
}

%input {
  border: 1px solid $color-grey-interface--light !important;
  padding: 7px 15px !important;
  margin-bottom: 25px;
  border-radius: 0;
  width: 100%;
  min-width: 0;
  min-height: 40px;
  @extend .p--card;
  outline: 0;
  background-color: white;
  color: $color-text;
  margin-top: 0;

  &:focus {
    border: 1px solid $color-grey-interface !important;
  }
}

%select {
  @extend %input;
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDExIDEwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjYTBiNGJlOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggY2xhc3M9ImNscy0xIiBkPSJNOTI4LDEwMjMuMDFsLTUuNSwxMC01LjUtMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05MTcgLTEwMjMpIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-color: white;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  color: $color-text;

  option {
    color: $color-text;
  }
}

input.jcrop-keymgr {
  display: none;
}

.profile-menu {
  list-style: none;
  background-color: white;
  padding: 40px;
  margin-bottom: 50px;
  @include media-breakpoint-down(lg) {
    padding: 30px;
  }
  @include media-breakpoint-down(xs) {
    padding: 30px 15px;
    margin-bottom: 15px;
  }

  li {
    margin-top: 15px;
  }

  li:first-child {
    margin-top: 0;
  }

  a {
    font-size: 16px;
    line-height: 16px;
    display: inline;
  }

  .active {
    a {
      color: $color-grey-text-dark;
      font-family: $font-bold;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__title {
    position: relative;
    overflow: hidden;
    margin-top: 30px !important;
    font-size: 14px;
    line-height: 22px;
    color: $color-text;
    text-transform: none;
    font-weight: 400;
    @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
    }

    &--billing {
      margin-top: 0 !important;
    }

    span {
      color: $color-grey-interface--light;
      position: relative;
      background-color: white;
      padding-right: 5px;
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $color-grey-lines;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.flatpickr-input[readonly]:not(.input) {
  width: 100%;
  border: 0;
}

input#id_avatar {
  outline: none;
  color: $color-grey-text;
  font-size: 14px;
  line-height: 22px;
}

.profile-info-menu {
  background-color: white;
  box-shadow: inset 0 -2px 0 $color-grey-lines;

  ul {
    list-style: none;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
    align-items: center;
    height: 100%;
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .profile-info-menu__link {
      cursor: pointer;
      margin-top: 0;
      margin-right: 30px;
      color: $color-grey-text-dark;
      padding-top: 20px;
      padding-bottom: 20px;
      transition: 0.1s ease-in-out;

      &:hover:not(.profile-info-menu__link--active) {
        color: $color-a--hover;
      }

      &--active {
        color: $color-orange;
        font-family: $font-bold;
        box-shadow: inset 0 -4px 0 $color-orange;
      }
    }

    .profile-info-menu__link:last-child {
      margin-right: 0;
    }
  }
}

.progress-title {
  margin-top: 0;
  margin-bottom: 15px;
  @include media-breakpoint-down(xs) {
    margin-top: 15px;
  }
}

.progress {
  font-size: 0;
  height: 6px;
  background-color: $color-grey-lines;
  margin-bottom: 40px;
}

.progress-bar {
  background-color: #2cb8f2;
}

.profile-info {
  background-color: white;
  padding: 40px 30px;

  &--margin-bottom {
    margin-bottom: 50px;
  }
  @include media-breakpoint-down(xs) {
    padding: 30px 15px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    @include media-breakpoint-down(md) {
      justify-content: flex-start;
    }

    .p--normal {
      margin-top: 0;
      text-align: right;
    }
  }

  .input {
    width: 100%;
    color: $color-grey-dark;
    line-height: 26px;
  }

  &__row {
    margin-bottom: 20px;
  }

  .form-inline {
    input:not(.alt-button, .cta-button) {
      outline: none;
      padding: 0;
      border: none;
      color: $color-grey-text-dark;
      font-family: 'Clear Sans Regular';
      font-size: 16px;
      line-height: 26px;
      @include media-breakpoint-only(xs) {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
}

#password_form form input {
  padding: 7px 15px;
  border: 1px solid #aabdc6;
}

#password_form {
  .cta-button {
    margin-top: 15px;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 25px;
  }

  .form-group {
    margin-bottom: 25px;
  }
}

.avatar-img {
  font-size: 0;
  display: block;
  width: 100%;
  padding-top: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  background-color: $color-bg;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: none;
  }
}

.img-help-text {
  margin-top: 18px;
}

.gender-form-wrapper {
  height: 40px;
  display: flex;
  align-items: center;
}

.gender-form {
  ul {
    margin: 0;
  }

  label {
    font-family: 'Clear Sans Regular';
    font-size: 16px;
    line-height: 22px;
    color: $color-text;
    font-weight: 400;
    margin-right: 30px;
    margin-bottom: 0;
    @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 21px;
    }

    input[type=radio] {
      margin-right: 5px;
    }
  }
}

.callback__checkbox {
  display: flex;

  .p--small {
    margin-top: 0;
    margin-bottom: 0;
    color: $color-grey-text-dark;
  }

  input[type="checkbox"] {
    flex-shrink: 0;
    margin-right: 12px;
    margin-top: 4px;
    cursor: pointer;
  }
}

select#id_country {
  width: 100%;
  border: 0;
  height: 100%;
}
// Errors

.profile-info {
  .p-error {
    position: static;
    margin-top: 5px;
  }
}
// Social

.social-img {
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-position: center;
  margin-right: 9px;
  display: inline-block;
}

.soc_icons {
  line-height: 1;
}

.soc_icons a {
  display: inline-block;
  margin-top: 15px;
}

.soc_icon a:last-of-type .social-img {
  margin-right: 0;
}

.social-img:hover {
  cursor: pointer;
  box-shadow: none;
}

.social-img.fb_icon {
  background-color: #3e66be;
}

.social-img.g_icon {
  background-color: #e85644;
}

.social-img.in_icon {
  background-color: #0079b8;
}

.social-img.ok_icon {
  background-color: #e87f00;
}

.social-img.tw_icon {
  background-color: #55acee;
}

.social-img.vk_icon {
  background-color: #3384c2;
}

.social-img.yt_icon {
  background-color: #e9644b;
}

.social-img.no-acc {
  background-color: #d2d2d2;
}

.social-img.no-acc:hover {
  background-color: #a5a5a5;
}
// Переговорки

.coworking-chose {
  display: flex;
  flex-wrap: wrap;

  .alt-button {
    border: 0;
  }

  .btn-success {
    background-color: $color-grey-interface--light;
    pointer-events: none;
    font-family: $font-bold;
    color: white;
  }
}

.booking-calendar-bottom,
.booking-calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 0 30px 30px;
  background-color: white;
  @include media-breakpoint-down(xs) {
    padding: 0 15px;
  }

  span {
    margin: 0;
    @include media-breakpoint-down(xs) {
      font-size: 12px;
    }
  }
}

.booking-calendar-bottom {
  padding-top: 50px;
  margin-bottom: 50px;
  @include media-breakpoint-down(xs) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.date-link {
  cursor: pointer;
  color: $color-a;
  transition: 0.05s linear;
  text-decoration: none;

  &:hover {
    color: $color-a--hover;
    text-decoration: none;
    box-shadow: 0 1px 0 $color-a--hover;
  }
}

.booking-table {
  overflow-x: auto;
  margin: 0 !important;
  border-left: 0;
  border-right: 0;
  background-color: white;

  th {
    //     width: 340px;
    background-color: #e8eff2;
  }

  &__title {
    padding: 20px !important;
    text-align: center;
    @include media-breakpoint-down(xs) {
      padding: 15px !important;
    }

    h6 {
      color: $color-grey-text-dark;
      font-family: $font-bold;
      white-space: nowrap;
      min-width: 340px;
      @include media-breakpoint-down(xs) {
        min-width: 150px;
      }
    }
  }
}

.booking-time {
  padding-left: 30px !important;
  padding-top: 5px !important;
  padding-right: 10px !important;
  position: sticky !important;
  left: 0;
  background-color: white;
  z-index: 10;
  @include media-breakpoint-down(xs) {
    padding-left: 15px !important;
    padding-right: 10px !important;
  }
  font-size: 14px;
  line-height: 22px;
  color: $color-text;
  @include media-breakpoint-only(xs) {
    font-size: 13px;
    line-height: 20px;
  }
}

.booking-table-tr {
  border-top: 1px solid $color-grey-lines;
  position: relative;
}

.div-in-td {
  height: 25px;
}

.booking-table tr td:hover:not(.booking-time) {
  cursor: pointer;
  background-color: $color-green-light;
}

.close-booking {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 20px;
  line-height: 0.8;
  color: #a0b4be;
  font-weight: 300;
  opacity: 1;
  z-index: 2;

  &:hover {
    box-shadow: none;
    position: absolute;
  }
}

.booking-table td {
  position: relative;

  .remove-booking {
    color: $color-red;
  }

  .div-in-td {
    text-align: center;

    p {
      font-size: 14px;
      line-height: 22px;
      @include media-breakpoint-only(xs) {
        font-size: 13px;
        line-height: 20px;
      }
      margin: 0;
    }
  }
}

.booking-table {
  td.booking-alien {
    background-color: $color-red-light;
    padding: 10px;
    cursor: default;
    @include media-breakpoint-down(xs) {
      padding: 5px;
    }

    &:hover {
      background-color: $color-red-light !important;
    }

    .div-in-td {
      height: auto !important;
    }
  }

  td.booking-own {
    background-color: $color-green-light;
    padding: 10px;
    cursor: default;
    @include media-breakpoint-down(xs) {
      padding: 5px;
    }

    &:hover {
      background-color: $color-green-light !important;
    }

    .div-in-td {
      height: auto !important;
    }
  }
}

.booking-table-wrapper {
  position: relative;
}

.remove-booking {
  cursor: pointer;
}
// форма бронирования ======

.active-booking {
  background-color: #f6f9fa;

  .div-in-td:empty {
    display: none;
  }

  &:hover {
    background-color: #f6f9fa !important;
  }

  .b_form {
    padding: 10px;

    p {
      margin-top: 0;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      @include media-breakpoint-only(xs) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    ul {
      width: 100%;
      height: auto !important;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 25px;
      margin-top: 25px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;
        margin-top: 15px;
      }

      li {
        float: none;
        height: auto !important;
        margin-right: 0 !important;

        label {
          font-size: 14px;
          line-height: 22px;
          margin: 0 !important;
          @include media-breakpoint-only(xs) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }

    select {
      width: 50%;
      margin-bottom: 25px;
      border: 1px solid $color-grey-lines;
      @include media-breakpoint-down(xs) {
        width: 90%;
        margin-bottom: 15px;
      }

      option {
        font-size: 14px;
        line-height: 22px;
        @include media-breakpoint-only(xs) {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    .booking_form {
      text-align: center;
    }
  }
}

.modal-content {
  border-radius: 0;

  h4 {
    margin-top: 0;
  }

  .cta-button--accent-alt,
  .cta-button--minor {
    min-width: 130px;
  }

  .modal-footer {
    border-top: 0;
  }

  .modal-header {
    border-bottom: 0;
    position: relative;
  }
}
// ========================

.booking-calendar-links {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.success-calendar-icon {
  position: relative;
  width: 17px;
  height: 17px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-right: 5px;

  &--google {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij48dGl0bGU+aWNvbi1nY2FsPC90aXRsZT48cGF0aCBkPSJNMTIuNzEsMTdIMy41N0EzLjU3LDMuNTcsMCwwLDEsMCwxMy40M1YzLjU3QTMuNTcsMy41NywwLDAsMSwzLjU3LDBoOS44NUEzLjU3LDMuNTcsMCwwLDEsMTcsMy41N3Y5LjE0IiBzdHlsZT0iZmlsbDojM2E4M2YzIi8+PGNpcmNsZSBjeD0iMy45NiIgY3k9IjEuOTQiIHI9IjAuNzUiIHN0eWxlPSJmaWxsOiNmZmYiLz48Y2lyY2xlIGN4PSIxMy4wNCIgY3k9IjEuOTQiIHI9IjAuNzUiIHN0eWxlPSJmaWxsOiNmZmYiLz48cGF0aCBkPSJNNSw4LjFINS45YTEuMTEsMS4xMSwwLDAsMCwuODYtLjNBMS4yLDEuMiwwLDAsMCw3LDdhMS4xLDEuMSwwLDAsMC0uMjgtLjc4QTEuMDYsMS4wNiwwLDAsMCw2LDUuODhhMS4xNCwxLjE0LDAsMCwwLS43Ny4yNy44OC44OCwwLDAsMC0uMzEuN0gzLjN2MEExLjg0LDEuODQsMCwwLDEsNCw1LjI0YTIuOTEsMi45MSwwLDAsMSwxLjg5LS42MiwzLjExLDMuMTEsMCwwLDEsMiwuNiwyLjA3LDIuMDcsMCwwLDEsLjc1LDEuNzEsMS43MiwxLjcyLDAsMCwxLS4zMywxLDIuMjIsMi4yMiwwLDAsMS0uOTIuNzQsMi4wNywyLjA3LDAsMCwxLDEsLjczLDEuOTMsMS45MywwLDAsMSwuMzUsMS4xNUEyLjExLDIuMTEsMCwwLDEsOCwxMi4zMiwzLjI1LDMuMjUsMCwwLDEsNS45MSwxMywzLjE3LDMuMTcsMCwwLDEsNCwxMi4zNmExLjkxLDEuOTEsMCwwLDEtLjc4LTEuNjl2MEg0Ljc3YTEsMSwwLDAsMCwuMzMuNzcsMS4yMSwxLjIxLDAsMCwwLC44NS4zLDEuMjMsMS4yMywwLDAsMCwuODktLjMxLDEuMTEsMS4xMSwwLDAsMCwuMzMtLjg0LDEuMjIsMS4yMiwwLDAsMC0uMzItLjk0LDEuMzQsMS4zNCwwLDAsMC0uOTQtLjNINVoiIHN0eWxlPSJmaWxsOiNmZmYiLz48cGF0aCBkPSJNMTMuNDQsMTIuODVIMTEuODFWNi4xOEgxMC4xNlY1bDMuMjctLjI5WiIgc3R5bGU9ImZpbGw6I2ZmZiIvPjxwb2x5Z29uIHBvaW50cz0iMTIuNzEgMTcgMTcgMTIuNzEgMTIuNzEgMTIuNzEgMTIuNzEgMTciIHN0eWxlPSJmaWxsOiNhMWMzZmYiLz48cG9seWxpbmUgcG9pbnRzPSIxMi43MSAxMi43MSA4LjQzIDE3IDEyLjcxIDE3IiBzdHlsZT0iZmlsbDojMjc1N2ExIi8+PC9zdmc+");
  }

  &--ical {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij48dGl0bGU+aWNvbi1pY2FsPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjE1LjgzIDQuNDEgMC43NCA3LjQyIDAgMy41IDE1LjEgMC41IDE1LjgzIDQuNDEiIHN0eWxlPSJmaWxsOiNjZjU4NTQiLz48cG9seWdvbiBwb2ludHM9IjE1LjgzIDQuNDEgMC43NCA3LjQyIDIuNDUgMTYuNSAxNC43MyAxNC4wNiAxNyAxMC42IDE1LjgzIDQuNDEiIHN0eWxlPSJmaWxsOiNmZmYiLz48cGF0aCBkPSJNNC42NSw5LjkzLDQuNDgsOUE1LjUyLDUuNTIsMCwwLDAsNS4zLDguOGExLjIsMS4yLDAsMCwwLC41NS0uNDdBMS4xOCwxLjE4LDAsMCwwLDYsNy44MmExLjEzLDEuMTMsMCwwLDAsMC0uMjdsMS0uMjEsMS4yNCw2LjU2TDcsMTQuMTUsNi4xNSw5LjYzWiIgc3R5bGU9ImZpbGw6IzM1MzUzNSIvPjxwYXRoIGQ9Ik0xMy42MSw3LjE2QTguMzgsOC4zOCwwLDAsMCwxMyw4LjM0YTguNyw4LjcsMCwwLDAtLjc2LDMuMjcsNi40Nyw2LjQ3LDAsMCwwLC4wNiwxLjQ5bC0xLjMuMjZhOC4yMyw4LjIzLDAsMCwxLC40NC0zLjg5LDguOTMsOC45MywwLDAsMSwuODUtMS44OGwtMy4xOC42My0uMi0xLjE2LDQuNTEtLjlaIiBzdHlsZT0iZmlsbDojMzUzNTM1Ii8+PHBhdGggZD0iTTIuNzMsNS44NGEuODIuODIsMCwwLDEsMCwuMzguNTEuNTEsMCwwLDEtLjQzLjMzLjU1LjU1LDAsMCwxLS4zNywwLC40OS40OSwwLDAsMS0uMjItLjM3bDAtLjEzLjIzLDAsMCwuMTNBLjM0LjM0LDAsMCwwLDIsNi4zYS4yNi4yNiwwLDAsMCwuMjIsMCwuMjYuMjYsMCwwLDAsLjIzLS4xOS45My45MywwLDAsMCwwLS4zM0wyLjI0LDQuNThsLjI0LDBaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBhdGggZD0iTTMuMTIsNC40MWwuMjIsMS4xNWEuNjkuNjksMCwwLDAsLjE0LjMyQS4zOS4zOSwwLDAsMCwzLjg4LDZhLjQ1LjQ1LDAsMCwwLC4zOS0uMy43Mi43MiwwLDAsMCwwLS4zM0w0LjA1LDQuMjJsLjI1LDAsLjIsMWExLjI2LDEuMjYsMCwwLDEsMCwuNTUuNjUuNjUsMCwwLDEtLjU2LjQ2QS42My42MywwLDAsMSwzLjI2LDZhMS4yNCwxLjI0LDAsMCwxLS4xOS0uNTFsLS4yLTFaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBhdGggZD0iTTQuNjcsNC4xbC4yNCwwLC4zMSwxLjY0LjkxLS4xOCwwLC4yMkw1LDZaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBvbHlsaW5lIHBvaW50cz0iMTQuNzMgMTQuMDYgMTQuMTggMTEuMTYgMTcgMTAuNiIgc3R5bGU9ImZpbGw6I2NjYyIvPjwvc3ZnPg==");
  }

  &--outlook {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij48dGl0bGU+aWNvbi1vY2FsPC90aXRsZT48cGF0aCBkPSJNNi44Miw0LjM2SDQuNzVWNi41NEg2LjgyWm0yLjgzLDBINy41OFY2LjU0SDkuNjVabTIuNzYsMEgxMC4zNFY2LjU0aDIuMDdabTIuNzYsMEgxMy4wOVY2LjU0aDIuMDdaTTYuODIsNy4xN0g0Ljc1VjkuMzVINi44MlptMi44MywwSDcuNThWOS4zNUg5LjY1Wm0yLjc2LDBIMTAuMzRWOS4zNWgyLjA3Wm0yLjc2LDBIMTMuMDlWOS4zNWgyLjA3Wm0tMTEuMSwwSDJWOS4zNUg0LjA2Wk02LjgyLDEwSDQuNzV2Mi4xOUg2LjgyWm0yLjgzLDBINy41OHYyLjE5SDkuNjVabTIuNzYsMEgxMC4zNHYyLjE5aDIuMDdabTIuNzYsMEgxMy4wOXYyLjE5aDIuMDdaTTQuMDYsMTBIMnYyLjE5SDQuMDZabTIuNzYsMi44MUg0Ljc1VjE1SDYuODJabTIuODMsMEg3LjU4VjE1SDkuNjVabTIuNzYsMEgxMC4zNFYxNWgyLjA3Wm0tOC4zNSwwSDJWMTVINC4wNlpNMTUuODUsMEgxLjE1QTEuMTYsMS4xNiwwLDAsMCwwLDEuMTdWMTUuODNBMS4xNiwxLjE2LDAsMCwwLDEuMTUsMTdoMTQuN0ExLjE2LDEuMTYsMCwwLDAsMTcsMTUuODNWMS4xN0ExLjE2LDEuMTYsMCwwLDAsMTUuODUsMFptLS4wOCwxNS42SDEuMjNWMy43M0gxNS43N1oiIHN0eWxlPSJmaWxsOiM5ZTc1ZmYiLz48L3N2Zz4=");
  }
}
// Заказ пропусков

.profile-info .order_pass_form {
  margin-top: 40px;
  margin-bottom: 50px;

  .form-group {
    position: relative;
    margin-bottom: 25px;

    span {
      position: absolute;
      top: 25px;
      left: 0;
      font-size: 14px;
      line-height: 22px;
      @include media-breakpoint-only(xs) {
        font-size: 13px;
        line-height: 20px;
      }
      margin: 0;
      @extend .gray-text;
      @include media-breakpoint-down(xs) {
        position: static;
      }
    }
  }

  .form-group input {
    @extend %input;
    margin-bottom: 0;
    margin-top: 0;
  }

  label {
    @extend .p--card;
    margin-top: 0;
    margin-bottom: 30px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }

  #id_visit_time {
    margin-top: -20px;
    display: inherit;
    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }
}

ul.errorlist.nonfield {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  color: #f23535;
  font-size: 14px;
  line-height: 22px;
  @include media-breakpoint-only(xs) {
    font-size: 13px;
    line-height: 20px;
  }
}

.search-order {
  display: flex;
  padding: 10px;
  background-color: $color-bg;
  width: 100%;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    margin-top: 25px;
  }

  input {
    @extend .input;
    border: 1px solid $color-grey-interface--light !important;
    padding: 7px 15px !important;
  }

  .cta-button {
    min-width: auto;
  }
}

.search-input-wrapper {
  position: relative;
  margin-right: 10px;
  flex-grow: 1;
  @include media-breakpoint-down(xs) {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .search-reset {
    position: absolute;
    top: 11px;
    right: 10px;
    color: $color-grey-interface--light;

    &:hover {
      box-shadow: none;
    }
  }
}

table.table-order {
  margin-top: 20px !important;
}

.order-old {
  background-color: white;
  padding-bottom: 25px;

  &__title {
    padding: 0 30px;
    @include media-breakpoint-down(xs) {
      padding: 0 15px;
    }
  }

  &__header {
    border-bottom: 1px solid $color-grey-lines;
  }

  tr:nth-child(even):not(.order-old__header) {
    background-color: #f6f9fa;
  }

  &__body {
    td {
      margin: 0;
      padding: 10px 30px;
      font-size: 14px;
      line-height: 22px;
      @include media-breakpoint-only(xs) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  th {
    padding: 20px 30px !important;
    @include media-breakpoint-down(xs) {
      padding: 15px !important;
    }

    h6 {
      color: $color-grey-text-dark;
      font-family: $font-bold;
      font-size: 14px;
      line-height: 22px;
      @include media-breakpoint-down(xs) {
        min-width: 150px;
      }
    }
  }

  .table {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.pagination-wrapper {
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-bottom: 50px;
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .page-item .page-link {
    color: $color-grey-text-dark;

    &:hover {
      box-shadow: none;
    }
  }

  .page-item.active .page-link {
    background-color: #a0b4be;
    border-color: #a0b4be;
    color: white;
    border-radius: 0;
    font-family: $font-bold;
    font-weight: 600;
  }

  .page-link {
    border: none;
    border-radius: 0;
    color: $color-a;
    font-size: 14px;
    padding: 5px;
    line-height: 1;
    min-width: 27px;
    text-align: center;

    &:hover {
      color: $color-grey-text-dark;
    }
  }

  li {
    margin-right: 5px;
    @include media-breakpoint-down(xs) {
      margin-right: 0;
    }
  }

  li:last-child {
    margin-right: 0;
  }

  .no-hover:hover {
    background-color: transparent;

    img {
      filter: contrast(120%);
    }
  }
}
// Полезная информация

.card {
  margin-bottom: 30px;
}

.h6--profile {
  color: $color-grey-text-dark;
  font-family: $font-bold;
  font-size: 14px;
  line-height: 22px;
}

.card-header {
  background-color: $color-bg;
  border-color: $color-grey-lines;
}

.card-block {
  &__title {
    p {
      margin: 0;
    }
  }
}

.card-block,
.panel-body {
  p {
    margin-top: 10px;
  }
}

.order-old__body {
  p {
    margin: 0;
  }
}

.card-block.order-old {
  padding: 0;
}

.card-block {
  h4 {
    margin-top: 20px;
  }
}

.tariffs-table {
  border-bottom: 0;

  .order-old__body td {
    padding: 0.75rem 1.25rem;
  }

  .order-old__header th {
    padding: 0.75rem 1.25rem !important;
  }
}

.profile-info {
  &--useful-info {
    margin-bottom: 50px;

    .coworking-chose {
      margin-bottom: 40px;
    }
  }

  .card {
    margin-bottom: 30px;
  }
}


// Создание коротких ссылок

.p--sm {
  @extend %p--small;
}

.short-urls {
  label {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .input {
    border: 1px solid $color-grey-interface--light !important;
    padding: 7px 15px !important;
    margin-bottom: 25px;
  }
}

.link-guide {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin-top: 0;
    margin-left: 40px;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

.link-history {
  margin-top: 25px;
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;

  li {
    margin-right: 15px;
    @include media-breakpoint-only(xs) {
      margin-right: 10px;
    }

    a:hover img {
      filter: saturate(200%);
    }
  }

  li:last-child {
    margin-right: 0;
  }

  select {
    font-size: 14px;
    line-height: 22px;
    color: $color-text;
    @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
    }
    margin: 0;
    border: 1px solid $color-grey-lines;
    appearance: none;
    border-radius: 0;
    padding: 3px 30px 3px 10px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDExIDEwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjYTBiNGJlOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggY2xhc3M9ImNscy0xIiBkPSJNOTI4LDEwMjMuMDFsLTUuNSwxMC01LjUtMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05MTcgLTEwMjMpIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-position: 90% 50%;
    outline: 0;
  }
}

.link-history-table {
  margin-top: 0 !important;

  th h6 {
    @include media-breakpoint-down(xs) {
      min-width: auto;
      writing-mode: vertical-lr;
      margin: 0;
    }
  }
}

.link-history-wrapper {
  margin-bottom: 50px;
}
// Создание сертификата

.certificate-form {
  span {
    font-size: 12px;
    line-height: 18px;
    text-transform: none;
    letter-spacing: 0.4px;
    @extend .gray-text;
  }

  label:not(.cta-button) {
    @extend .p--normal;
    margin-top: 0;
    margin-bottom: 5px;
  }

  textarea {
    @extend %input;
  }

  input:not(.cta-button) {
    @extend %input;
  }

  input[type="file"] {
    font-size: 14px;
    line-height: 22px;
    color: $color-grey-text;
    margin-top: 0;
    @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  #id_kurs_icon,
  #id_sign {
    margin-bottom: 0;
  }
}

.has-error .help-block {
  color: $color-red;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  left: 20px;
  bottom: -17px;
}

.certificate-wrapper {
  margin-bottom: 50px;
}

#id_kurs_icon,
#id_sign {
  font-size: 14px;
  line-height: 22px;
  @include media-breakpoint-only(xs) {
    font-size: 13px;
    line-height: 20px;
  }
}
// Профиль компании

.balans {
  &__info-link {
    min-height: 100px;
    width: 100%;
    border: 1px solid $color-grey-lines;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: 15px 30px;
    margin-bottom: 30px;
    background-color: white;
    @include media-breakpoint-down(xs) {
      padding: 15px; //       margin-bottom: 15px;
    }

    p {
      margin: 0;
      align-self: center;
    }
  }
}

a.balans__info-link {
  justify-content: space-between;

  img {
    flex-shrink: 0;
  }

  p {
    @extend a;
  }
}

.payers-info-wrapper {
  padding: 0 30px;
  background-color: white;
  padding-bottom: 40px;
  margin-bottom: 50px;
  @include media-breakpoint-down(xs) {
    padding-left: 0;
    padding-right: 0;
  }

/*
  .card {
    margin-bottom: 0;
  }
*/

table {
  border: none;

  td {
    font-family: 'Clear Sans Regular';
    font-size: 14px;
    line-height: 22px;
    color: $color-text;
    text-transform: none;
    font-weight: 400;
    @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
      vertical-align: bottom;
    }
  }

  td:nth-child(odd) {
    text-align: right;
    padding-right: 20px;
    @include media-breakpoint-only(xs) {
      padding-right: 7px;
      font-family: $font-bold;
    }
  }
}

.card-block {
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

&--mod {
  margin-bottom: 30px;

  td:nth-child(odd) {
    text-align: left !important;
  }
  table {
/*
      td {
        font-size: 16px;
        line-height: 26px;
      @include media-breakpoint-only(xs) {
        font-size: 15px;
        line-height: 24px;
        }
      }
*/
}
}

}

.new-payment {
  .cta-button {
    margin-bottom: 15px;
  }
}
// Биллинг

.back-to-profile {
  background-color: $color-bg;
  border-radius: 0;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  width: 100%;
  margin-top: 40px;
  // @include media-breakpoint-down(xs) {
  //   margin-top: 50px;
  // }
  &:hover {
    box-shadow: none;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid white;
    border-bottom: 20px solid white;
    border-right: 20px solid $color-bg;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.payobjects-search {
  background-color: $color-bg;
  padding: 30px;
  margin-top: 40px;
  @include media-breakpoint-down(xs) {
    padding: 15px;
    margin-top: 20px;
  }

  .search-order {
    //     background-color: white;
    padding: 0;
    margin-top: 0;
  }

  .search-order .cta-button {
    min-width: 180px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: 40px;

      label {
        @extend .p--normal;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }
}

.total-payobjects {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  .cta-button {
    display: inline-block;
    min-width: auto;
    width: auto;
  }

  .p--sm {
    margin-top: 0;
    margin-right: 15px;
  }
}
// Вызов курьера

.courier-form {
  input {
    @extend %input;
  }

  label {
    @extend .p--normal;
  }
}

.select-full {
  select {
    @extend %input;
    appearance: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDExIDEwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjYTBiNGJlOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggY2xhc3M9ImNscy0xIiBkPSJNOTI4LDEwMjMuMDFsLTUuNSwxMC01LjUtMTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05MTcgLTEwMjMpIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-color: white;
    font-size: 16px;
    line-height: 26px;
    outline: none;
    color: $color-text;

    option {
      color: $color-text;
    }
  }
}

.input-all {
  @extend %input;
}

.problem-form {
  .form-group {
    margin-bottom: 0;
  }

  textarea {
    margin-bottom: 25px;
  }
}
// Типовая таблица в Профиле

.table-profile-wrapper {
  background-color: white;
  padding-bottom: 25px;
  margin-left: -30px;
  margin-right: -30px;
  @include media-breakpoint-down(xs) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.table-profile {
  border-left: 0 !important;
  border-right: 0 !important;

  .cta-button {
    min-width: auto;
  }

  &__title {
    padding: 0 30px;
    @include media-breakpoint-down(xs) {
      padding: 0 15px;
    }
  }

  &__header {
    border-bottom: 1px solid $color-grey-lines;

    th {
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: $color-grey-text-dark;
      font-family: $font-bold;
      font-size: 14px;
      line-height: 22px;
      @include media-breakpoint-down(xs) {
        min-width: 150px;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }

  tr:nth-child(even):not(.order-old__header) {
    background-color: #f6f9fa;
  }

  &__body {
    td {
      margin: 0;
      padding: 10px 15px;
      font-size: 14px;
      line-height: 22px;
      vertical-align: middle;
      @include media-breakpoint-only(xs) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  th {
    padding: 15px !important;
    vertical-align: middle;

    h6 {
      color: $color-grey-text-dark;
      font-family: $font-bold;
      font-size: 14px;
      line-height: 22px;
      @include media-breakpoint-down(xs) {
        min-width: 150px;
      }
    }
  }

  td:first-child,
  th:first-child {
    padding-left: 30px !important;
    @include media-breakpoint-down(xs) {
      padding-left: 15px !important;
    }
  }

  td:last-child,
  th:last-child {
    padding-right: 30px !important;
    @include media-breakpoint-down(xs) {
      padding-right: 15px !important;
    }
  }
}

.table-profile__header--mob {
  th {
    @include media-breakpoint-down(xs) {
      min-width: auto;
    }
  }
}

table.table-profile {
  margin-top: 0 !important;
}

.mt-30 {
  margin-top: 30px;
}

.exit-link {
  margin-top: 30px;
  display: inline-block !important;

  img {
    margin-left: 5px;
  }
}

.statistic-menu {
  background-color: white;
  box-shadow: inset 0 -2px 0 $color-grey-lines;
  padding: 0 30px;
  border-bottom: none;
  @include media-breakpoint-down(xs) {
    padding: 0 15px;
  }
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border: none;  
}


.nav-tabs--profile .nav-link {
  border: none;
  border-radius: 0;
  margin-top: 0;
  margin-right: 30px;
  color: $color-grey-text-dark;
  padding-top: 0;
  padding-bottom: 20px;
  transition: 0.1s ease-in-out;
}

.nav-tabs--profile .nav-link:hover {
  color: $color-a--hover;
}

.nav-tabs--profile .nav-link.active {
  height: 100%;
  border: none;
  color: $color-orange;
  font-family: $font-bold;
  box-shadow: inset 0 -4px 0 $color-orange;
}

.coworking-choice-wrapper {
  background-color: white;
  padding: 40px 30px;
}

.profile-info-menu {
  background-color: white;
  box-shadow: inset 0 -2px 0 $color-grey-lines;

  ul {
    list-style: none;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
    align-items: center;
    height: 100%;
    border-bottom: none;
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
      overflow-x: scroll;
    }

    .profile-info-menu__link {
      cursor: pointer;
      margin-top: 0;
      margin-right: 30px;
      color: $color-grey-text-dark;
      padding-top: 20px;
      padding-bottom: 20px;
      transition: 0.1s ease-in-out;
      @include media-breakpoint-down(xs) {
        white-space: nowrap;
      }

      &:hover:not(.profile-info-menu__link--active) {
        color: $color-a--hover;
      }

      &--active {
        color: $color-orange;
        font-family: $font-bold;
        box-shadow: inset 0 -4px 0 $color-orange;
      }
    }

    .profile-info-menu__link:last-child {
      margin-right: 0;
    }
  }
}

#statistic-scope {
  svg {
    width: initial;
    height: initial;
  }

  .modebar-btn:hover {
    box-shadow: none;
  }
}

#users {
  overflow-x: auto;
}

.problem-form {
  .cta-button {
    margin-top: 15px;
  }
}

.card {
  border-color: $color-grey-lines;
}

.profile-info-wrapper {
  background-color: white;
}
// Форматируем серверные формы

.format-form {
  label {
    @extend .p--card;
  }

  span {
    @extend %p--small;
    @extend .gray-text;
    display: block;
    margin-top: 0;
    margin-bottom: 15px;
  }

  input[type="file"] {
    color: $color-text;
    font-size: 14px;
  }

  input[type="text"],
  input[type="password"] {
    @extend %input;
  }

  h4 {
    margin-top: 25px;
  }
}
// Сотрудники

.tarifs-residents {
  margin-top: 10px;
}

.team-member {
  padding: 30px 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: 0.2s linear;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 380px;
  @include media-breakpoint-down(xs) {
    border-color: $color-grey-lines;
    padding: 15px;
    margin-bottom: 30px;
  }

  &:hover {
    border-color: $color-grey-lines;
  }

  &__pic {
    width: 157px;
    height: 157px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: $color-bg;
    box-shadow: 0 0 0 1px $color-bg;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .caption p {
    @include media-breakpoint-down(xs) {
      margin-top: 15px;
    }
  }
  
  &--moderator {
    margin-top: 0 !important;
    margin-bottom: 40px;
    
    .team-member__pic {
      width: 100px;
      height: 100px;
    }
    
    .responsible {
      transform: translateY(80px);
    }
  }
  
  
}

.responsible {
  font-size: 12px;
  line-height: 22px;
  color: $color-orange;
  border: 1px solid $color-orange;
  border-radius: 15px;
  padding: 0 5px;
  text-align: center;
  background-color: white;
  display: inline-block;
  transform: translateY(139px);
  position: relative;
  z-index: 2;
}

.add-empls {
  input.form-control {
    @extend %input;
    width: 100%;
  }
}

.change-tariffs-btn {
  display: none;
}

.actual-tariffs {
  margin-top: 0;
}

.change-tariffs {
  min-height: 50px;

  &:hover .actual-tariffs {
    display: none;
  }

  &:hover .change-tariffs-btn {
    display: inline-block;
  }
}

.logo-download {
  &__img {
    width: 100%;
    height: 110px;
    background-color: $color-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  a.cta-button {
    margin-bottom: 0;
    display: inline-block;
    margin-top: 30px;
    @include media-breakpoint-down(xs) {
      margin-top: 25px;
    }
  }

  .p--tiny {
    margin-top: 15px;
  }
}

#logo_preview {
  background-color: $color-bg;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
}

.payer-active-field {
  display: flex;
  align-items: center;
}

.custom-form {
  label {
    text-align: right;
    color: $color-grey-text;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 20px;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  input {
    margin-bottom: 0 !important;
  }

  h4 {
    margin-bottom: 20px;
  }

  select {
    @extend %select;
    margin-bottom: 0;
  }
  
  &--horizontal {
    label {
      margin-bottom: 8px;
    }
    
    .input, select {
      margin-bottom: 15px !important;
    }
  }
}

.form-row {
  margin-bottom: 20px;
}

.form-column {
  justify-content: flex-end;
  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
  }
}

.resubmit-initial {
  font-size: 12px;
  color: $color-grey-text !important;

  &:hover {
    box-shadow: none;
  }
}

.margin-label {
  label {
    margin-bottom: 5px !important;
  }
}
// для вертикальных инпутов

.input-group {
  input {
    @extend %input;
    width: 100% !important;
  }

  select {
    @extend %select;
    width: 100% !important;
  }

  label {
    @extend .p--card;
    margin-bottom: 10px;
    margin-top: 30px;
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column;
  }
}

.inline-btn {
  .cta-button {
    display: inline-block;
  }
}

.team-member--userlist {
  flex-direction: row;
  min-height: auto;
  padding: 0;
  margin-top: 60px;
  text-align: left;
  border: none;
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-grey-lines;
  }

  .team-member__pic {
    margin: 0;
    flex-shrink: 0;
    text-align: center;
  }

  &:hover {
    border: none;
  }
  
  .team-member__info {
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
    }
  }
  
  
}

.team-member__info {
  flex-grow: 1;
  margin-left: 30px;
  @include media-breakpoint-down(sm) {
    margin-top: 30px;
    margin-left: 0;
    align-self: flex-start;
  }

  &__title {
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }

    p {
      margin-top: 0;
    }
  }

  &__body {
    p {
      margin-top: 0;
    }
  }
}

.pagination-wrapper--userlist {
  margin-top: 60px;
  padding: 0;
  margin-bottom: 0;
}

.overflow-menu {
  .nav-link {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.4;
    padding: 20px 0;
  }
  .nav-item {
    margin-bottom: 0;
    background-color: white;
    box-shadow: inset 0 -2px 0 $color-grey-lines;
  }
}

.profile-tabs {
  .nav-link {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .nav-item {
    margin-bottom: 0;
    background-color: white;
    box-shadow: inset 0 -2px 0 $color-grey-lines;
    @include media-breakpoint-down(sm) {
      min-width: 150px;
    }
  }
}

.success {
  background-color: #e3f7de !important;
}

.correction-balance__button {
  display: none;
}

.correction-balance:hover {
  .correction-balance__button {
    display: block;
  }
  .balance {
    display: none;
  }
}

.payers-info-wrapper--client {
  padding: 0;
  @include media-breakpoint-down(sm) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.add-pay {
  background-color: $color-bg;
  border: none;
  padding: 30px;
  
  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
  
  .input {
    width: 150px;
    min-width: 150px;
    margin-right: 10px;
  }
  
  .p--big {
    margin-top: 0;
  }
}

.bill_history {
  display: flex;
  flex-wrap: wrap;
}


.tiny-label {
  label {
    @extend .p--tiny;
    text-align: left;
    margin-top: 15px; 
  }
}

.resubmit-clear__label {
  margin-top: 15px !important;
  font-size: 12px !important;
  color: $color-text !important;
}

.resubmit-initial {
  word-break: break-word;
  margin-bottom: 10px;
  display: inline-block;
  
  @include media-breakpoint-down(xs) {
//     display: none;
}
}

.checkbox-wrapper {
  margin-top: 15px;
  display: flex;
  input {
    margin-right: 15px;
  }
}

.delete-row {
  margin-bottom: 10px;
  margin-top: auto;
  @include media-breakpoint-down(xs) {
    margin-left: 15px;
    margin-top: 15px;
  } 
}


.add-row {
  @extend .cta-button;
  @extend .cta-button--minor;
  display: inline-block !important;
  margin-top: 20px;
}

.input-group--mb0 {
  input, select {
    margin-bottom: 0;
  }
} 

.mt-mob {
  @include media-breakpoint-down(sm) {
    margin-top: 30px !important;
  }
}

.map-coworking-wrapper {
  margin-top: 40px;
}

.invite-form-password {
  margin-top: 30px;

  .profile-info__label {
    height: auto;
  }
}

.login__footer--invite {
  text-align: left;

  .p--sm {
    margin-bottom: 40px;
  }
}

.profile-menu--invite {
  .p--normal {
    margin: 0;
    color: $color-grey-text-dark;
  }

  .active .p--normal {
    font-family: $font-bold;
  }
}

.form-column--right {
  text-align: right;
  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}


.commonform-photo {
 display: block;
 width: 100%;
 padding-top: 50%;
 padding-bottom: 50%;
 border-radius: 50%;
 background-position: center top;
 background-repeat: no-repeat;
 background-size: cover;
 background-color: $color-bg;

 &--pass {
  border-radius: 0;
  padding: 0;
  height: 77px;
  width: 105px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
 }
}

#id_gender {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  list-style: none;
  padding-left: 0;

  li {
    margin-right: 30px;
  }

  li:last-child {
    margin-right: 0;
  }
}

#id_no_inn {
  margin-right: 10px;
}

.add-new-payer {
  text-align: right;

  @include media-breakpoint-down(sm) {
    text-align: left;
    margin-bottom: 15px;
  }
}


.pagination {
  flex-wrap: wrap;
}

.close-correction {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  color: #a0b4be;
  font-size: 30px;
  font-weight: 300;
  cursor: pointer;
}

.hurma {
    
  ul {
      display: flex;
      height: auto;
      
      input[type="radio"] {
        min-height: auto !important;
        width: auto !important;
        margin-right: 5px !important;
      }
      
      label {
        margin-bottom: 0;
      }
      
      li {
        margin-right: 30px !important;
      }
  }

}

  .hurma label[for="id_radio"] {
    display: none !important;
  }

.table-profile tr:hover,
.order-old__body:hover {
    background-color: #e4ecf0 !important;
}

.p--small {
  font-family: 'Clear Sans Regular';
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
  }
}


.userpic {
  
  .team-member__pic {
    position: relative;

    &::after {
      position: absolute;
      border-radius: 50%;
      
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0,0,0,0.4);
      transition: 0.2s linear;
      
    }
  }
  
  &:hover {
    .team-member__pic::after {
      content: 'Загрузить';
    }
  }
  
}


