.section--80 {
    padding-top: 80px;
    padding-bottom: 80px;
    @include media-breakpoint-down(xs) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.corporate-form {
  form {
    margin-top: 80px;
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
    .p--mega {
      margin: 0;
    }
    .input {
      width: 100%;
    }
    .cta-button {
      margin-top: 60px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      @include media-breakpoint-down(xs) {
        margin-top: 30px;
      }
    }
    .message-error:not(.input) {
      position: absolute;
      top: 20px;
      left: 30px;
      color: red;
      margin: 0;
    }
    input.message-error {
      border-color: red;
    }
  }
  &__row {
    margin-top: 30px;
    @include media-breakpoint-down(xs) {
      margin-top: 15px;
    }
  }
  &__label {
    justify-content: flex-end;
    @include media-breakpoint-down(xs) {
      justify-content: flex-start;
    }
  }
  &__input {
    position: relative;
  }
}