.svg-all {
	display: none;
}

svg {
  height: 100%;
  width: 100%;
}

.svg {
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&--elem-reload svg {
		fill: inherit;
	}

	&--elem-arrowdown {
		width: 10px;
		height: 20px;

		svg {
			fill: inherit;
		}
	}
}

.footer-menu {
	.svg {
		&--icon-fb {
			background-color: $color--fb;

			svg {
				width: 55%;
				height: 55%;
			}
		}

		&--icon-vk {
			background-color: $color--vk;

			svg {
				width: 60%;
				height: 60%;
			}
		}

		&--icon-instgrm {
			background: $color--instgrm;

			svg {
				width: 60%;
				height: 60%;
			}
		}

		&--icon-youtube {
			background: $color--youtube;

			svg {
				width: 68%;
				height: 60%;
			}
		}

		& svg {
			fill: #fff;
		}
	}
}

.share-panel__item .svg {
	width: 20px;

	&--icon-fb svg {
		fill: $color--fb;
	}

	&--icon-vk svg {
		fill: $color--vk;
	}

	&--icon-tw svg {
		fill: $color--tw;
	}
}

.selection svg {
	width: 80%;
	height: 80%;
}

.block--tweet .svg--icon-tw {
	width: 20px;
	height: 20px;

	svg {
		fill: $color--tw;
	}
}

.menu__logo .svg--logo-tceh-on-b {
	position: relative;
	top: -2px;
	@include media-breakpoint-only(xs) {
// 		top: -4px;
	}
}

.login__close .svg--elem-cross svg {
	width: 100%;
	height: 100%;
	fill: $color-grey-interface !important;

	&:hover {
		fill: $color-grey-lines !important;
	}
}

.submenu__close .svg--elem-cross svg {
	width: 50%;
	height: 50%;
}

.login .svg {
	border-radius: 5px;

	&--icon-fb {
		background-color: $color--fb;

		svg {
			width: 68%;
			height: 68%;
		}

		&:hover {
			background-color: lighten($color--fb, 10%);
		}
	}

	&--icon-vk {
		background-color: $color--vk;

		svg {
			width: 68%;
			height: 68%;
		}

		&:hover {
			background-color: lighten($color--vk, 10%);
		}
	}

	&--icon-g {
		background-color: $color--g;

		svg {
			width: 68%;
			height: 68%;
		}

		&:hover {
			background-color: lighten($color--g, 10%);
		}
	}

	& svg {
		fill: #fff;
	}
}
