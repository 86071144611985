.edu-filters {
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin-bottom: 50px;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      margin-bottom: 35px;
    }
    li {
      min-width: 100px;
      margin-right: 15px;
      cursor: pointer;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 25px;
      background-color: white;
      border-radius: 30px;
      transition: 0.2s linear;
       @include media-breakpoint-down(sm) {
         margin-bottom: 15px;
         padding: 10px 25px;
       }
      &:hover {
        box-shadow: 0 0 0 1px $color-grey-text;
      }
    }
    li:last-child {
      margin-right: 0;
    }
  }
  .filter-active {
    background-color: $color-grey-interface--light;
    pointer-events: none;
    border: none !important;
    h6 {
      font-family: $font-bold;
      color: white;
    }
  }
}