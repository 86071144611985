// @mixin font-regular() {
//   font-family: 'ClearSansRegular';
//   font-weight: normal;
//   font-style: normal;
// }
//
// @mixin font-bold() {
//   font-family: 'ClearSansBold';
//   font-weight: bold;
//   font-style: normal;
// }
//
//
// @mixin font-light() {
//   font-family: 'ClearSansLight';
//   font-weight: lighter;
//   font-style: normal;
// }
//
//
// @mixin font-italic() {
//   font-family: 'ClearSansItalic';
//   font-weight: lighter;
//   font-style: normal;
// }
//
//
// @mixin font-bold-italic() {
//   font-family: 'ClearSansBoldItalic';
//   font-weight: bold;
//   font-style: italic;
// }
//




@mixin hover-bg-img() {
  // transform: scale(1.05);
}





@mixin a() {
  color: $color-a;
  border-bottom: 1px solid transparent;

  &:hover {
    color: $color-a--hover;
    border-bottom-color: $color-a--hover;
  }
}





@mixin a--without-line() {
  color: $color-a;

  &:hover {
    color: $color-a--hover;
  }
}





@mixin a--underline() {
  color: $color-a;
  border-bottom: 1px solid $color-a;

  &:hover {
    color: $color-a--hover;
    border-bottom-color: $color-a--hover;
  }
}





@mixin a--without-color-with-underline() {
  color: inherit;
  border-bottom: 1px solid inherit;

  &:hover {
    color: $color-a--hover;
    border-bottom-color: $color-a--hover;
  }
}




@mixin a--hover() {
  border-bottom: 1px solid transparent;

  &:hover {
    color: $color-a--hover;
    border-bottom-color: $color-a--hover;
  }
}





@mixin a--hovered--underline() {
  color: $color-a--hover;
  text-decoration: underline;
}





@mixin font-s-h($font-size, $line-height) {
  font-size: $font-size/$rem;
  // line-height: $line-height/$rem;
  line-height: $line-height/$font-size;
}





@mixin content-padding(){
  @include media-breakpoint-up(sm) {
    padding-right: ($grid-gutter-width-base/1px)/$rem;
    padding-left: ($grid-gutter-width-base/1px)/$rem;
  }
}





@mixin first-text($margin-top: $margin-top-default) {
  margin-top: $margin-top;

  &:first-child {
    margin-top: 0;
  }
}





@mixin media-player($ratio) {
  position: relative;
  display: block;

  width: 100%;
  height: 0;

  @if $ratio == 16by9 {
    padding-bottom: 56.25%;
  } @else if $ratio == 4by3 {
    padding-bottom: 73%;
  } @else {
    padding-bottom: 56.25%;
  }

  overflow: hidden;

  background-color: #353535;
  background-size: cover;
  background-position: center;
}





@mixin player() {
  position: absolute;

  height: 100%;
  width: 100%;

  border: 0;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}





@mixin bg-gradient {
  $second-color: #ff4800;
  background-color: $color-orange;
  -webkit-background-image: -webkit-gradient(linear, 100% 0, 0 0, from($color-orange), to($second-color));
  -webkit-background-image: -webkit-linear-gradient(right, $color-orange,  $second-color);
  -moz-background-image: -moz-linear-gradient(right, $color-orange,  $second-color);
  -o-background-image: -o-linear-gradient(right, $color-orange,  $second-color);
  background-image: linear-gradient(to right, $color-orange, $second-color);
}





@mixin x-multiple-colored-gradient ($args...) {

  $gradient: ();
  $pos: nth($args, 1);
  $pos_newsyntax: ();

  @if not is-valid-keyword-direction($pos) {
    $pos: 'top';
  }

  // New Syntax
  @if $pos == 'top' {
    $pos_newsyntax: 'to bottom';
  } @else if $pos == 'right' {
    $pos_newsyntax: 'to left';
  } @else if $pos == 'bottom' {
    $pos_newsyntax: 'to top';
  } @else if $pos == 'left' {
    $pos_newsyntax: 'to right';
  }

  @each $g in $args {
    @if not is-valid-keyword-direction($g) {
      $gradient: append($gradient, $g, comma);
    }
  }

  background-image: -webkit-linear-gradient(unquote($pos), $gradient);
  background-image:         linear-gradient(unquote($pos_newsyntax), $gradient);
}


@function is-valid-keyword-direction($value) {
  @return not not index(
  'top' 'right' 'bottom' 'left'
  'to top' 'to right' 'to bottom' 'to left'
  'to top right' 'to right top'
  'to bottom right' 'to right bottom'
  'to top left' 'to left top'
  'to bottom left' 'to left bottom', $value);
}





/**
* How to make 3-corner-rounded triangle in CSS (SO)
* http://stackoverflow.com/q/14446677/1397351
*/
.triangle,
.triangle:before,
.triangle:after {
  width: 4em;
  height: 4em;
}
.triangle {
  overflow: hidden;
  position: relative;

  margin: auto;

  border-radius: 16%;
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(.866);
  cursor: pointer;
  pointer-events: none;
  // background-color: rgba(106, 230, 127, 0.52);
}
.triangle:before, .triangle:after {
  width: inherit;
  height: inherit;
  position: absolute;
  background: #fff;
  pointer-events: auto;
  content: '';
}
.triangle:before {
  // background-color: rgba(80, 145, 226, 0.54);
  border-radius: 20% 20% 20% 53%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%)
  skewX(30deg) scaleY(.866) translateX(-24%);
}
.triangle:after {
  // background-color: rgba(226, 91, 159, 0.52);
  border-radius: 20% 20% 53% 20%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%)
  skewX(-30deg) scaleY(.866) translateX(24%);
}
//
// .triangle:hover { overflow: visible; }
// .triangle:hover:before, .triangle:hover:after { background: none; }
// .triangle:hover, .triangle:hover:before, .triangle:hover:after {
// 	border: dashed 1px;
// }
