// Отзывы студентов

.feedback {
    &__text {
        background-color: white;
        min-height: 190px;
        height: auto;
        width: 100%;
        position: relative;
        z-index: 2;
        padding: 35px 30px;

        &::after {
            content: '';
            position: absolute;
            bottom: -9px;
            left: 20px;
            width: 30px;
            height: 30px;
            background-color: white;
            transform: rotate(45deg);
        }
    }

    p {
        margin: 0;
    }
    // Блок с фото и ФИО
    &__author {
        display: flex;
        align-items: flex-start;
        margin-top: 30px;
        margin-bottom: 50px;
    }
    &__author-img {
        width: 70px;
        height: 70px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        flex-shrink: 0;
    }
    &__name {
        font-family: $font-bold;
        margin-top: 0;
        span {
            font-family: $font-regular;
        }
    }
}
