.author-image {
	margin-bottom: 40px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
	}


	&__img {
		padding-right: 0;
	}

	&__fio {
		margin-top: 0;
		@include media-breakpoint-down(sm) {
			line-height: inherit;
		}
	}

	&__description {
		margin-top: 0.2em;
	}
}
