.host {
    background-color: white;
    margin-bottom: 60px;

    &__desc {
        padding: 50px 30px;
        @include media-breakpoint-down(xs) {
            padding: 25px 20px;
        }

        .p--card {
            color: $color-grey-text;
        }

        h3 {
            margin-top: 0;
        }
    }

    &__title-link {
        color: inherit !important;

        &:hover {
            color: $color-a--hover !important;
        }
    }

    .cta-button {
        width: 100%;
        margin-top: 40px;
    }

    .slick-list,
    .slick-track,
    .slider,
    .slider__wrapper {
        height: 100% !important;
        @include media-breakpoint-down(xs) {
            height: 200px !important;
        }
    }
}

.hosts-banner {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    h2 {
        color: white;
        text-align: center;
    }
}

.slider__item {
    position: relative;
    height: 450px;
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
}

.slick-slide img {
    // display: block;
    // position: absolute;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
