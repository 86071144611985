

.table-wrapper, .scroll-wrapper {
  position: relative;
  overflow-x: auto;
  
  &::-webkit-scrollbar-thumb {
  border: 2px solid $color-grey-interface;
 
  }
  
  &::-webkit-scrollbar {
  background: $color-grey-lines;
  height: 2px;
  }
  
  &::-webkit-scrollbar-track {
    background-color: $color-grey-lines;
  }
  
  &::-webkit-scrollbar-button {
    width: 20px;  
  }
}

.scroll-wrapper--nobar {
  
  &::-webkit-scrollbar {
  height: 0;
  }
  
  &::-webkit-scrollbar-thumb {
  border: none;
 
  }
  
}


.block--header {
	margin-top: 50px;
}


.block--note {
	padding-left: 40px;
	padding-top: 5px;
	padding-bottom: 5px;

	border-left-width: 10px;
	border-left-style: solid;
	border-left-color: $color-grey-lines;

	margin-top: 30px;
	margin-bottom: 30px;
	font-style: italic;
	 @include media-breakpoint-down(xs) {
		 padding-left: 15px;
	 }
	p {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
    font-size: 16px;
    line-height: 26px;
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 24px;
  }
}
}




.block--text {
	margin-bottom: 30px;
  margin-top: 30px;
  
	p {
		@extend .p--big;
	}


	li {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

	}

}

/*
.event-content .block--text {
    overflow-x: initial;
}
*/



.block--tweet {
	padding-left: 40px;
	padding-top: 15px;
	padding-bottom: 15px;

	border-width: 0 0 0 10px;
	border-style: solid;
	word-wrap: break-word;

	margin-top: 60px;
	margin-bottom: 60px;
	 @include media-breakpoint-down(xs) {
		 padding-left: 15px;
	 }

	> a {
		margin-top: 15px;
		display: inline-block;

		span {
			vertical-align: middle;
		}

		.svg {
			vertical-align: middle;
		}


		&:hover {

			span {
				// @include a--hovered--underline();
				cursor: pointer;
				box-shadow: 0 1px 0 $color-a--hover;
				color: $color-a--hover;
			}

			svg {
				fill: $color-a--hover;
			}

		}


	}
}

.block--image-small {
	margin-top: 60px;
	margin-bottom: 60px;
	img {
		max-width: 100%;
		height: auto;
		margin-bottom: 5px;
	}
}


.block--quote {
	margin-top: 60px;
	margin-bottom: 60px;
	&__text {
		font-family:'Clear Sans Italic';
		font-style: italic;
		margin-top: 0;
	//	> p {
		//	display: inline;
	//	}
	}


	&__img {
		max-width: 120px;
		max-height: 120px;
		width: 100%;
		height: 100%;
		margin-top: 10px !important;
		@include media-breakpoint-down(xs) {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}

		.block--quote__img {
			background-color: #d2e0e6;
		}
	}

}


.block--image-fullscreen {
	img {
		width: 100%;
		height: auto;
		margin-bottom: 5px;
	}

	&__text {
		margin-top: 15px !important;
	}
}

.text-under-img {
		p {
		font-family: Clear Sans Regular;
    font-size: 14px!important;
    line-height: 22px!important;
    margin-top: 15px!important;
    margin-bottom: 0!important;
    color: #8999a9!important;
    text-transform: none!important;
    font-weight: 400!important;
	}
}

.author {
	&__description {
		margin-top: 0.2em;
	}
}


.comments-mini {
	text-align: right;

	p {
		margin-top: 0;

		a {
			text-decoration: none;
		    border-bottom-width: 1px;
		    border-bottom-style: solid;
		    border-bottom-color: transparent;
			border-bottom-color: $color-a;
			border-bottom-style: dashed;

			&:hover {
				box-shadow: none;
				border-bottom-color: $color-a--hover;
			}

		}

	}

	@include media-breakpoint-down(sm) {
		margin-top: 15px;
		text-align: left;
	}
}



.block--conclusions {
	margin-top: 60px;
	margin-bottom: 60px;
	h4 {
		margin-bottom: 30px;
	}
	&__conclusion {
		margin-top: 0;

		&__counter {
			display: block;
			float: left;
			width: 35px;
			height: 35px;

			margin-right: 15px;

			line-height: 35px;
			text-align: center;

			font-size: 18px;
			font-family:'Clear Sans Bold';
			color: #f9700b;

			background-color: #f2f5f9;
		}


		&__text {
			margin-top: 0;
			padding-top: 2px;
			margin-bottom: 30px;
			display: block;
			overflow: hidden;
			// width: calc(100% - #{$margin});
		}
	}
}




.title-block {
	margin-bottom: 45px;

	@include media-breakpoint-up(sm) {
		padding-left: 30px;
	}

	&_material-type {
		margin-top: 40px;
		margin-bottom: 20px;
		text-transform: uppercase;
		@include media-breakpoint-down(xs) {
			margin-top: 20px;
		}
	}
}

.main-content {
	position: relative;

	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 40px;
	@include media-breakpoint-down(xs) {
		padding-left: 0;
		padding-right: 0;
	}

	&:after {
		content: '';
		border-bottom: 1px solid $color-grey-lines;
		display: block;
		height: 1px;
		width: calc(100% - #{($grid-gutter-width-base/1px)/$rem*2});
		bottom: 0;
		left: ($grid-gutter-width-base/1px)/$rem;
		position: absolute;
	}
}



.teaser {
	overflow: hidden;

	&--small {
		margin-top: 30px;
		position: relative;
		height: 155px;
		padding: 15px;

		.teaser__material-type {
			margin-top: 0;
			color: $color-text--light;
			position: relative;
			text-shadow: 1px 1px 1px $color-grey-dark;
		}

		.teaser__title {
			color: $color-text--light;
			width: 90%;
			position: absolute;
			bottom: 15px;
		}
	}

	&--big {
		margin-top: 30px;

		.wrap {
			height: 165px;
			width: 100%;
			overflow: hidden;
			position: relative;
			margin-bottom: 20px;
		}

		.teaser__title {
			display: inline;
			border-bottom: 1px solid transparent;
		}
		.teaser__text {
			@extend .p--card;
			margin-top: 20px;
		}

		&:hover {
			.teaser__title {
				@extend a:hover;
			}
		}
	}


	&:hover {
		.teaser__img {
			@include hover-bg-img();
		}
		.overlay {
			@extend .overlay--hover;
		}
	}


	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.2s linear;
	}
}


.selection {
  min-height: 300px;
	margin-top: 50px;
	
	&__body {
		position: relative;
		padding: 35px 30px;
		background-color: #fff;
		display: flex;
    flex-direction: column;
    justify-content: space-between;
        

		&__title {
			margin: 0;
			a {
				color: inherit;
				&:hover {
					color: $color-a--hover;
				}
			}
		}


		&__link {
			&--more {
				display: none;
			}

			&--visible {
				display: block;
			}

		}


		&__social {
			display: flex;
			flex-basis: auto;
			align-items: center;
			justify-content: space-between;
			color: $color-grey-text;
			margin-top: 10px;


			&__icons {
				white-space: nowrap;
				text-align: right;

				a {
					display: inline-block;
					margin-left: 3px;
					width: 20px;
					height: 21px;
					background: $color-grey-interface--light;
					fill: $color-text--light;
					cursor: pointer;

					&:hover {
						background: $color-grey-text;
					}
				}

			}


			&__link {
				height: 20px;
				width: 20px;
				display: inline-block;
				opacity: 0.6;

				&:hover {
					opacity: 1;
				}


				&--vk {
					background: url('../img/tceh2/icon-vk--small.png') left no-repeat;
				}

				&--fb {
					background: url('../img/tceh2/icon-fb--small.png') left no-repeat;
				}
				&--tw {
					background: url('../img/tceh2/icon-tw--small.png') left no-repeat;
				}
			}
		}
	}
}



// .material {
// 		margin-bottom: 100px;
// 		&-menu {
// 			margin-bottom: 60px;
// 		}
// }



.block--question-answer {
	// @include first-text();
	margin-top: 60px;
	margin-bottom: 60px;

	&__answer {
		margin-top: 30px;
	}

}



.media-player {
	@include media-player(16by9);
}


#page-cover__player__id {
	@include player();
}


.title-with-two-columns {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	flex-wrap: wrap;
}


.special-width {
	max-width: 20%;
	min-width: 20%;
}
.flex {
	display: flex;
	align-items: center;
	height: 100px;
}
