//
// @name Default CTA button
//
// @description
//
// @state .cta-button--big - Bigbutton
// @state .cta-button--mega - Megabutton
//
// @state .cta-button--big cta-button--all-width - Bigbutton на всю ширину
//
// @state .cta-button--accent - Accent
// @state .cta-button--accent cta-button--big - Accent Bigbutton
// @state .cta-button--accent cta-button--mega - Accent Megabutton
//
// @state .cta-button--accent-alt - Accent-alt
// @state .cta-button--accent-alt cta-button--big - Accent-alt Bigbutton
// @state .cta-button--accent-alt cta-button--mega - Accent-alt Megabutton
//
// @state .cta-button--minor - Minor
// @state .cta-button--minor cta-button--big - Minor Bigbutton
// @state .cta-button--minor cta-button--mega - Minor Megabutton
//
// @markup
//   <a href="#" class="cta-button">Записаться на курс</a>
//
.cta-button {
	&, &:link, &:visited, &:focus {
		display: inline-block;
		border-radius: 0;
		width: 100%;
		min-width: 0;
		min-height: 40px;

		@include media-breakpoint-up(md) {
			min-width: 180px;
			width: auto;
		}

		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 15px;
		padding-right: 15px;

		vertical-align: top;

		outline: none;
		border: 0;

		font-size: 14px;
		line-height: 1.2;
		color: $color-text--light !important;
		text-align: center;

		background-color: $color-grey-interface;
		text-decoration: none;
		cursor: pointer;

		box-shadow: 0 -2px 0 0 $color-shadow-button inset;

		will-change: transform;

	}


	&:active {
		transform: translateY(2px);
		box-shadow: none;
	}

	&:hover {
		background-color: $color-grey-interface--light;
		color: $color-text--light;
		text-decoration: none;
		border: none;
		box-shadow: 0 -2px 0 0 $color-shadow-button inset;
	}


	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}



	&--big {
		&, &:link, &:visited, &:focus {
			font-size: 18px;
			min-height: 55px;
			@include media-breakpoint-up(md) {
				min-width: 240px;
			}

			padding-top: 16px;
			padding-bottom: 16px;
		}
	}



	&--mega {
		&, &:link, &:visited {
			font-size: 22px;
			min-height: 65px;
			@include media-breakpoint-up(md) {
				min-width: 287px;
			}

			padding-top: 17px;
			padding-bottom: 17px;

			box-shadow: 0 -3px 0 0 $color-shadow-button inset;
		}

		&:active {
			box-shadow: none;
		}
	}



	&--all-width {
		&, &:link, &:visited {
			min-width: 100% !important;
		}
	}



	&--accent {
		&, &:link, &:visited {
			$second-color: #ff4800;
			@include x-multiple-colored-gradient('left', $second-color 0%, $color-orange 50%, $second-color 100%);

			background-color: $color-orange;
			background-size: 200% auto;
			background-position: 100% 0;
		}

		&:hover {
			background-color: $color-orange-medium;
			animation: gradient-position 1.4s ease infinite;
		}

		&-alt {
			&, &:link, &:visited, &:focus {
				background-color: $color-a;
			}

			&:hover {
				background-color: $color-a--hover;
			}

		}

	}



	&--minor {
		&, &:link, &:visited, &:focus {
			background-color: transparent;

			border: 1px solid $color-grey-interface;
			border-radius: 4px;

			color: $color-grey-interface !important;
			box-shadow: none;

			text-transform: uppercase;
			letter-spacing: 0.8px;

			display: block;
			text-align: center;
			cursor: pointer;
		}


		&:hover {
			color: $color-text--light !important;
			border: 1px solid $color-grey-interface--light;
			background-color: $color-grey-interface--light;
      box-shadow: none;
		}
	}


	&[class*='--minor'][class*='--big'] {
		border-radius: 6px;
	}


	&[class*='--minor'][class*='--mega'] {
		border-radius: 8px;
	}


}





//
// @name Загрузить ещё
//
// @description Загрузить ещё
//
// @markup
//   <div class="load-more">
//     <span class="load-more__link">Загрузить ещё</span>
//   </div>
//
.load-more {
	display: inline-block;

	margin-top: 2.5em;

	text-align: center;
	cursor: pointer;

	will-change: transform;

	@include a--without-line();

	&__img {
		width: 45/$rem;
		height: 45/$rem;

		margin-left: auto;
		margin-right: auto;

		fill: $color-a;
	}


	&--loading &__img {
		animation: roll 0.9s linear infinite reverse;
	}


	&:hover &__img {
		fill: $color-a--hover;
		animation: roll 1.5s cubic-bezier(0.47, 0, 0.75, 0.72) 2 reverse;
	}

	&__link {
		margin-top: 1em;
		font-size: 22/$rem;
	}
	&--edu {
  	margin-top: 10px;
	}

}

@keyframes roll {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}





//
// @name Кнопка Play
//
// @description Кнопка Play
//
// @markup
// <div class="button-play">
//   <div class="triangle"></div>
// </div>
//
.button-play {
	margin-left: auto;
	margin-right: auto;

	width: 150/$rem;
	height: 150/$rem;

	cursor: pointer;

	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.3);

	transition: background-color 0.2s ease;


	&:hover {
		background-color: rgba(#000, 0.7);
	}


	.triangle {
		width: 4em;
		height: 4em;

		transform: translateY(89%) translateX(17%) rotate(0deg) skewY(30deg) scaleX(0.866);
	}
}

.alt-button {
  min-width: 100px;
  margin-right: 15px;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  background-color: white;
  border-radius: 30px;
  transition: 0.2s linear;
  font-size: 16px;
  line-height: 22px;
  color: $color-grey-text;
  text-transform: uppercase;
  font-weight: 400;
  outline: none;
     @include media-breakpoint-down(sm) {
     margin-bottom: 15px;
     padding: 10px 25px;
   }
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 21px;
  }

  &:hover {
    box-shadow: 0 0 0 1px $color-grey-text;
  }
}

.alt-button--success {
    background-color: $color-grey-interface--light;
    pointer-events: none;
    border: none !important;
    font-family: $font-bold;
    color: white;
} 





// анимация на кнопку

.animation-btn {
  position: relative;
  transition: 0.4s linear;
  display: inline-block;
  width: 100%;
}

.animation-btn:not(.disabled):before {
  content: '';
  position: absolute;
  right: 30px;
  top: 51px;
  width: 20px;
  height: 20px;
  border: 4px solid;
  border-left-color: transparent;
  border-bottom-color: white;
  border-right-color: white;
  border-top-color: white;
  border-radius: 50%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  z-index: 30;
}
.sbreg:not(.disabled):after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0px;
  transition-delay: 0.5s;
  transition-duration: 0.75s;
  transition-property: width;
}
.animation-btn.sending sbreg:not(.disabled) {
  pointer-events: none;
  cursor: not-allowed;
}
.sbreg:not(.disabled).sending {
//   text-align: left;
// text-indent: -40px;
}
.animation-btn.sending:before {
  transition-delay: 0.5s;
  transition-duration: 1s;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.animation-btn.sending:after {
  transition-delay: 0s;
  width: 20px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


