.participants-block {
  padding-top: 40px;
  h4 {
    margin-bottom: 40px;
    margin-top: 0;
    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
    }
  }
  .item {
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  margin-right: 15px;
  object-fit: cover;
  @include media-breakpoint-down(xs) {
    margin-right: 0 !important;
    margin-bottom: 0;
    width: 10vw !important;
    height: 10vw !important;
    border-radius: 0;
  }
 }
 .masonry {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   @include media-breakpoint-down(xs) {
    justify-content: flex-start;
   }
   .item:last-child:not(.item--link) {
     margin-right: auto !important;
   }
 }
 .show-more-div {
  display: inline-block;
  @include media-breakpoint-down(xs) {
    width: auto !important;
    height: auto !important;
    margin-left: 10px;
  }
}
}

.participants {
  .masonry {
    .profile-item-link:hover {
      box-shadow: none;
      opacity: 0.8;
    }
  }
}





