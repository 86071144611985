.events-card {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: $color-grey-dark;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-bottom: none;
	position: relative;
	overflow: hidden;
	margin-top: 0;
	margin-bottom: 30px;

	@media (max-width: 320px) {
		padding: 25px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0,0,0,0.4);
		z-index: 1;
	}

	h3 {
		margin-top: 0;
		a {
			color: white;

			&:hover {
				color: $color-a--hover;
				box-shadow: none;
			}
		}
	}

	p {
		color: white;
	}

	&__tag {
		text-transform: uppercase;
		color: $color-orange;
		border: 1px solid $color-orange;
		border-radius: 2px;
		padding: 1px 7px;
		margin-top: 0;
		vertical-align: middle;
		display: inline-block;
	}

	* {
		position: relative;
		z-index: 2;
	}

	&__desc {
		flex-grow: 1;
		margin-bottom: 0;
		margin-top: 25px;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 25px;

		.cta-button {
			@include media-breakpoint-down(sm) {
				width: auto;
			} 
		}
	}
}