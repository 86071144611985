.slider {
    background-color: #111124;
    width: 100%;
    height: 720px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        height: 650px;
    }
    @include media-breakpoint-down(lg) {
        height: 550px;
    }
    @include media-breakpoint-down(xs) {
        height: 300px;
    }
    &__item {
        width: 100%;
        height: 720px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-down(lg) {
            height: 650px;
        }
        @include media-breakpoint-down(lg) {
            height: 550px;
        }
        @include media-breakpoint-down(xs) {
            height: 300px;
        }
    }
}

.arrow {
    width: 60px;
    height: 60px;
    background-color: $color-grey-verydark;
    opacity: 0.7;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s linear;
    @include media-breakpoint-down(xs) {
        width: 30px;
        height: 30px;
    }
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 1;
    }
    &--prev {
        left: 0;
        &::after {
            content: '';
            width: 25px;
            height: 25px;
            border-top: 8px solid white;
            border-left: 8px solid white;
            transform: rotate(-45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            z-index: 5;
            @include media-breakpoint-down(xs) {
                width: 15px;
                height: 15px;
                right: 5px;
                border-top: 3px solid white;
                border-left: 3px solid white;
            }
        }
    }
    &--next {
        right: 0;
        &::after {
            content: '';
            width: 25px;
            height: 25px;
            border-top: 8px solid white;
            border-right: 8px solid white;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 15px;
            margin: auto;
            z-index: 5;
            @include media-breakpoint-down(xs) {
                width: 15px;
                height: 15px;
                left: 5px;
                border-top: 3px solid white;
                border-right: 3px solid white;
            }
        }
    }
}
