body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

/*override bootstrap*/
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

body {
    font-size: 16px;
}

em {
  font-family:'Clear Sans Italic';
}

strong, .bold-text {
  font-family: $font-bold !important;
}

.gray-text {
    color: $color-grey-text !important;
}

.gray-dark-text {
  color: $color-grey-text-dark !important;
}

.orange-text {
  color: $color-orange !important;
}

.white-text {
  color: white !important;
}

.blue-text {
  color: $color-a;
}

.red-medium-text {
  color: $color-red-medium !important;
}

.light-text {
  font-family: 'Clear Sans Light', sans-serif !important;
  font-weight: 300;
}

.color-green-text {
  color: $color-green !important;
}

label span.orange-text {
  color: $color-orange !important;
  display: inline;
}

/* СПИСКИ */

.progressable-content {
    ul, ol {
    padding-left: 40px;
    margin-top: 30px;
    @include media-breakpoint-only(xs) {
      padding-left: 20px;
    }
  }
  
  ul {
    list-style: none;
  }
  
  li {
    font-family: 'Clear Sans Regular';
    font-size: 18px;
    line-height: 30px;
    color: $color-text;
    margin-top: 30px;
    text-transform: none;
    @include media-breakpoint-only(xs) {
        font-size: 16px;
        line-height: 26px;
    }
  }
  
  ul {
    li {
        position: relative;
      &::before {
        content: '.';
        color: $color-grey-text;
        font-size: 50px;
        position: absolute;
        left: -20px;
        top: -17px;
      }
    }
  }

}

/* ССЫЛКИ*/
.link,
a,
a:active,
a:focus,
a:visited {
  color: $color-a;
  transition: 0.05s linear;
  text-decoration: none;
  cursor: pointer;
}

a:hover, .link:hover {
  position: relative;
  color: $color-a--hover;
  text-decoration: none;
  box-shadow: 0 1px 0 $color-a--hover;
}

a.without-hover-line:hover,
.link.without-hover-line:hover {
  // border-bottom-color: transparent;
  box-shadow: none;
}

// все ссылки в формах должны быть подчеркнуты
.form-changeable-area {
  a:not(.cta-button):not(.cta-button--minor):not(.without-hover-line) {
    box-shadow: 0 1px 0 currentColor;
    &:hover {
      box-shadow: none;
    }
  }
}



/* Заголовки*/
h1, .h1 {
  font-family: 'Clear Sans Bold';
  font-size: 55px;
  line-height: 60px;
  color: $color-text;
  text-transform: none;
  margin-top: 0;
  @include media-breakpoint-only(xs) {
    font-size: 30px;
    line-height: 35px;
  }
}


h2 {
  font-family: 'Clear Sans Bold';
  margin-top: 45px;
  color: $color-text;
  text-transform: none;
  font-size: 40px;
  line-height: 45px;
  @include media-breakpoint-only(xs) {
      font-size: 28px;
      line-height: 35px;
  }
}

.h2,
.h2--alt {
    font-family: 'Clear Sans Light';
    font-weight: 300;
    margin-top: 45px;
    color: $color-text;
    text-transform: none;
    font-size: 40px;
    line-height: 45px;
    @include media-breakpoint-only(xs) {
        font-size: 28px;
        line-height: 35px;
    }
}

.h3,
h3 {
  font-family: 'Clear Sans Bold';
  font-size: 28px;
  line-height: 33px;
  color: $color-text;
  text-transform: none;
  margin-top: 45px;
  @include media-breakpoint-only(xs) {
      font-size: 24px;
      line-height: 28px;
  }
}


.h3--alt {
  font-family: 'Clear Sans Light';
  font-size: 28px;
  line-height: 33px;
  color: $color-text;
  text-transform: none;
  margin-top: 45px;
  font-weight: 300;
  @include media-breakpoint-only(xs) {
      font-size: 24px;
      line-height: 28px;
  }
}

.h4,
h4 {
  font-family: 'Clear Sans Bold';
  font-size: 20px;
  line-height: 26px;
  margin-top: 45px;
  color: $color-text;
  text-transform: none;
  @include media-breakpoint-only(xs) {
      font-size: 20px;
      line-height: 23px;
  }
}

.h5,
h5 {
  font-family: 'Clear Sans Bold';
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
  margin-top: 15px;
  text-transform: none;
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 21px;
  }
}

.h6,
h6 {
  font-family: 'Clear Sans Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $color-grey-text;
  text-transform: uppercase;
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 21px;
  }
}

/* ПАРАГРАФЫ*/

/*
p {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-transform: inherit;
}
*/


p {
  font-family: 'Clear Sans Regular';
  font-size: 14px;
  line-height: 22px;
  margin-top: 30px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
  }
}

/* Параграф обычный*/
.p--normal {
  font-family: 'Clear Sans Regular';
  font-size: 16px;
  line-height: 26px;
  color: $color-text;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 24px;
  }
}

/* Текст мелкий*/

.p--small {
  font-family: 'Clear Sans Regular';
  font-size: 14px;
  line-height: 22px;
  color: $color-text;
  margin-top: 16px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
  }
}

/* Параграф крупный*/
.p--big {
  font-family: 'Clear Sans Regular';
  font-size: 18px;
  line-height: 30px;
  color: $color-text;
  margin-top: 30px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 16px;
      line-height: 26px;
  }
}

/* Текст в карточках*/
.p--card {
  font-family: 'Clear Sans Regular';
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 15px;
      line-height: 21px;
  }
}

/* Параграф МЕГА*/
.p--mega {
  font-family: 'Clear Sans Regular';
  font-size: 25px;
  line-height: 40px;
  color: $color-text;
  margin-top: 40px;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 20px;
      line-height: 30px;
  }
}

/* Текст крохотный*/
.p--tiny {
  font-family: 'Clear Sans Regular';
  font-size: 12px;
  line-height: 18px;
  color: $color-text;
  text-transform: none;
  letter-spacing: 0.4px;
  font-weight: 400;
}

/* Дополнительный текст*/
.p--further {
  font-family: 'Clear Sans Regular';
  font-size: 14px;
  line-height: 22px;
  margin-top: 15px;
  margin-bottom: 0;
  color: $color-grey-text;
  text-transform: none;
  font-weight: 400;
  @include media-breakpoint-only(xs) {
      font-size: 13px;
      line-height: 20px;
  }
}

// Тонкий шрифт

.typo-light {
  font-family: 'Clear Sans Light';
  font-weight: 300;
}

